import { TrainingResponseType } from "@libs/common.types";

type LowNoiseListeningQuizContentProps = {
  isCompleted: boolean;
  isViewSentence: boolean;
  trainingItemProgress: number;
  handleViewSentence: () => void;
  trainingItems: { context: string }[];
  handleResponse: (response: TrainingResponseType) => void;
  responseCounts: { correct: number; incorrect: number };
  currentResponse: TrainingResponseType | null;
};

export default function LowNoiseListeningQuizContent({
  isCompleted,
  isViewSentence,
  handleViewSentence,
  trainingItems,
  trainingItemProgress,
  currentResponse,
  handleResponse,
  responseCounts
}: LowNoiseListeningQuizContentProps) {
  return (
    <div className="flex flex-col justify-center items-center w-full min-h-[300px] max-h-full overflow-x-hidden overflow-y-scroll no-scrollbar">
      {!isViewSentence && !isCompleted && (
        <>
          <p className="font-noto-sans-kr text-2xl font-semibold text-white leading-[160%] text-center break-keep mt-0 mb-3 mx-0 underline">
            아래 플레이 버튼을 눌러 문장을 잘 들어보세요.
            <br />잘 들으신 뒤 문장을 확인하세요.
          </p>
          <button
            className="flex justify-center items-center min-w-[80px] text-xl font-semibold break-keep bg-white px-8 py-3 rounded-[100px]"
            onClick={handleViewSentence}
          >
            문장확인
          </button>
        </>
      )}
      {isViewSentence && !isCompleted && (
        <>
          <p className="text-2xl font-semibold text-white leading-[160%] text-center break-keep mt-0 mb-3 mx-0">
            {trainingItems[trainingItemProgress - 1].context}
          </p>
          <div className="flex justify-center items-center gap-4 w-[85vw] flex-wrap text-white">
            <button
              className={`flex justify-center items-center min-w-[80px] text-[20px] font-semibold break-keep p-4 rounded-[100px] ${
                currentResponse?.response === "correct"
                  ? "bg-[#63b4ff]"
                  : "bg-[#8696bb]"
              }`}
              onClick={() =>
                handleResponse({ answer: "T", response: "correct" })
              }
            >
              잘들렸어요
            </button>
            <button
              className={`flex justify-center items-center min-w-[80px] text-[20px] font-semibold break-keep p-4 rounded-[100px] ${
                currentResponse?.response === "incorrect"
                  ? "bg-[#63b4ff]"
                  : "bg-[#8696bb]"
              }`}
              onClick={() =>
                handleResponse({ answer: "F", response: "incorrect" })
              }
            >
              안들렸어요
            </button>
          </div>
        </>
      )}
      {isCompleted && !isViewSentence && (
        <>
          <p className="text-white font-bold text-[24px] leading-7 text-center tracking-[1px] break-keep">
            짝짝짝! <br />
            모든 문장을 다 들었습니다. <br />
            이제 다음 듣기를 해 볼까요?
          </p>
          <hr className="w-[30%] mx-0 my-4" />
          <div className="flex gap-4">
            <img
              src={`${process.env.PUBLIC_URL}/images/test/trophy.png`}
              alt="Trophy Icon"
            />
            <div className="text-white font-bold text-[24px] leading-7 text-center tracking-[1px]">
              <p>잘들린 문장: {responseCounts.correct}개</p>
              <p>안들린 문장: {responseCounts.incorrect}개</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
