import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import ListeningLinks from "@components/mobile/ListeningLinks";

import { ROUTES } from "@constants/common.routes";
import { TrainingTypes } from "@constants/trainingType";

import type { TrainingGroupDataType } from "@libs/common.types";
import useAppData from "@hooks/useAppData";

export default function TopicListeningDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const { main, sub } = useParams();
  const data = location.state;

  const [trainingGroup, setTrainingGroup] = useState<TrainingGroupDataType[]>(
    []
  );
  const { getTrainingGroupList } = useAppData();

  useEffect(() => {
    if (data) {
      getTrainingGroupList(data.code).then((_data) => {
        if (_data && _data.length > 0) {
          const result = _data.reduce(
            (
              acc: TrainingGroupDataType[],
              item: { training_type: string; id: string; name: string }
            ) => {
              let entry = acc.find(
                (r: { training_type: string }) =>
                  r.training_type === item.training_type
              );
              if (!entry) {
                entry = {
                  index: TrainingTypes.find((t) => t.key === item.training_type)
                    ?.index as number,
                  training_type: item.training_type,
                  items: []
                };
                acc.push(entry);
              }
              entry.items.push({ id: item.id, name: item.name });
              return acc;
            },
            []
          );
          setTrainingGroup(result);
        }
      });
    }
  }, [data]);

  const navigateToBack = () => {
    navigate(`${ROUTES.CATEGORY}/${main}`);
  };

  return (
    <div className="bg-[#fff] w-full h-[calc(100vh_-_75px)] pt-[30px] pb-0 px-0 max-sm:h-screen max-sm:overflow-x-hidden max-sm:p-0">
      <div className="max-w-[1440px] h-auto mx-auto my-0 pt-0 pb-[30px] px-[30px] max-sm:h-auto max-sm:p-[30px]">
        <div className="font-noto-sans-kr flex justify-center items-center gap-4 w-full text-[#0d1b34] mt-0 mb-4 mx-0 max-sm:justify-between max-sm:gap-0">
          <p className="text-[32px] font-black tracking-[1px]">{data.name}</p>
          <button
            onClick={navigateToBack}
            className="font-noto-sans-kr text-[18px] font-normal underline max-sm:text-[16px]"
          >
            뒤로가기
          </button>
        </div>
        <p className="font-medium text-[#8696bb] text-center mt-0 mb-3 mx-0 max-sm:text-left">
          프로그램과 테마를 선택하세요
        </p>
        {trainingGroup
          .sort((a, b) => a.index - b.index)
          .map((type: TrainingGroupDataType) => {
            const { index, training_type, items } = type;
            console.log("TrainingGroupDataType", type);
            console.log("data", data);

            return (
              <ListeningLinks
                key={training_type}
                type={training_type}
                items={items}
                categoryData={{
                  data: data,
                  main: main,
                  sub: sub,
                  p_code: data.p_code,
                  code: data.code
                }}
              />
            );
          })}
      </div>
    </div>
  );
}
