import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import ListeningLinks from "@components/mobile/ListeningLinks";

import { ROUTES } from "@constants/common.routes";

import { DailyTrainingState, ITrainingItem } from "@states/DailyTrainingState";
import { UserAuthState } from "@states/UserAuthState";

import useAppData from "@hooks/useAppData";

export default function DailyTrainingMain() {
  const [testAuth, setTestAuth] = useState(false);
  const [testIndex, setTestIndex] = useState(1);
  const [testMaxIndex, setMaxTestIndex] = useState(1);
  const authState = useRecoilValue(UserAuthState);
  const dtState = useRecoilValue(DailyTrainingState);
  const location = useLocation();
  const appData = useAppData();
  const { user } = authState;
  let dtLogCount = dtState.dailyCount;

  let title = null;
  let dtItem = null;
  let BottomLinkComponent = () => <></>;

  /* 테스트: 오늘의 학습 셋 리스트 확인 */
  const selectIndexTrainingSet = async (index: number) => {
    await appData.getDailyTrainingSet(index);
    await appData.updateTrainingSet();
  };

  const nextIndex = () => {
    if (testIndex < 100) {
      if (testMaxIndex > testIndex) {
        setTestIndex(testIndex + 1);
      }
    }
  };

  const prevIndex = () => {
    if (testIndex > 1) {
      setTestIndex(testIndex - 1);
    }
  };

  useEffect(() => {
    if (user && user.email === "test@ihab.co.kr") {
      setTestAuth(true);
      appData.getMaxTrainingSetIndex().then((result) => {
        const maxIndex = result.data?.index || dtLogCount;
        // console.log("maxIndex", maxIndex);
        setMaxTestIndex(maxIndex);
      });
    }
  }, [user]);

  /* 테스트: END */

  if (location.pathname === ROUTES.TODAY.MAIN) {
    title = "오늘의 듣기";
    dtItem = dtState.today;
    BottomLinkComponent = () => (
      <Link
        to={ROUTES.YESTERDAY.MAIN}
        className="font-noto-sans-kr block font-normal text-[#8696bb] underline text-center mt-[30px] mb-0 mx-0"
      >
        어제의 듣기 다시보기
      </Link>
    );
  } else if (location.pathname === ROUTES.YESTERDAY.MAIN) {
    title = "어제의 듣기";
    dtItem = dtState.yesterday;
    BottomLinkComponent = () => (
      <Link
        to={ROUTES.TODAY.MAIN}
        className="font-noto-sans-kr block font-normal text-[#8696bb] underline text-center mt-[30px] mb-0 mx-0"
      >
        오늘의 듣기 다시보기
      </Link>
    );
    dtLogCount--;
  }

  const ListenLinksComponent = ({ dt }: { dt: ITrainingItem[] | null }) => {
    return (
      <>
        {dt &&
          dt.length > 0 &&
          dt.map((trainingItem: ITrainingItem) => {
            const { type, items } = trainingItem;
            return (
              <ListeningLinks
                key={type.key}
                type={type.key}
                items={items}
                categoryData={items.map((item) => ({
                  p_code: item.category_main_code,
                  code: item.category_sub_code
                }))}
              />
            );
          })}
      </>
    );
  };

  return (
    <div className="bg-[#fff] w-full h-[calc(100vh_-_75px)] pt-[30px] pb-0 px-0 max-sm:h-screen max-sm:overflow-x-hidden max-sm:pt-0">
      <div className="max-w-[1440px] h-auto mx-auto my-0 pt-0 pb-[30px] px-[30px] max-sm:h-auto max-sm:p-[30px]">
        <div className="font-noto-sans-kr flex justify-center items-center gap-4 w-full text-[#0d1b34] mt-0 mb-4 mx-0 max-sm:justify-between max-sm:gap-0">
          <p className="text-[32px] font-black tracking-[1px]">
            {title && title}
          </p>
          <p className="text-[20px] font-semibold">{dtLogCount}일차</p>
        </div>
        {testAuth && (
          // 테스트 계정일 경우만 이전, 다음 날짜로 이동 가능
          <div className="flex items-end justify-center m-3.5">
            <p>
              <button onClick={prevIndex}>[PREV]</button>
            </p>
            <p>TrainSetIdx: {testIndex}</p>
            <p>
              <button onClick={() => selectIndexTrainingSet(testIndex)}>
                <b>[GO]</b>
              </button>
            </p>
            <p>
              <button onClick={() => selectIndexTrainingSet(dtLogCount)}>
                <b>[RESET]</b>
              </button>
            </p>
            <p>
              <button onClick={nextIndex}>[NEXT]</button>
            </p>
          </div>
        )}
        <p className="font-medium text-[#8696bb] text-center mt-0 mb-3 mx-0 max-sm:text-left">
          주제를 선택하여 듣기 연습을 진행하세요
        </p>
        {dtState && <ListenLinksComponent dt={dtItem} />}
        <BottomLinkComponent />
      </div>
    </div>
  );
}
