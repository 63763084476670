import { useState, useEffect } from "react";

export default function usePlaybackSpeed(audio: HTMLAudioElement | null) {
  // 재생 속도 조절 로직
  const [speed, setSpeed] = useState(2); // 초기값은 보통 속도
  const speeds = [0.8, 0.9, 1.0, 1.1, 1.2]; // 재생 속도 배열

  useEffect(() => {
    if (audio) {
      audio.playbackRate = speeds[speed]; // speed 상태 변경 시 playbackRate 설정
    }
  }, [speed, audio]);

  const handleSpeedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSpeed(Number(event.target.value)); // 슬라이더 값으로 speed 설정
  };

  return { speed, handleSpeedChange };
}
