import React from "react";

type ListeningControlSectionProps = {
  backgroundStyle: React.CSSProperties;
  isCompleted: boolean;
  handlePlay: () => void;
  isPlay: boolean;
  currentWave: number;
  handleShowSettings: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleReset: () => void;
  navigateToNext: (pathname: string) => void;
  location: { pathname: string };
  state: { items: any[]; currentIndex: number };
  isStartSolvingQuiz?: boolean;
  isCheckAnswer?: boolean;
  handleCheckAnswer?: () => void;
};

export default function ListeningControlSection({
  backgroundStyle,
  isCompleted,
  handlePlay,
  isPlay,
  currentWave,
  handleShowSettings,
  handleReset,
  navigateToNext,
  location,
  state,
  isStartSolvingQuiz,
  isCheckAnswer,
  handleCheckAnswer
}: ListeningControlSectionProps) {
  return (
    // 듣기, 듣기 설정 등 제어 영역
    <section
      className={`flex-1 relative z-[1] flex flex-col items-center w-full h-full p-[30px] ${
        isCompleted ? "justify-start" : "justify-between"
      }`}
    >
      {/* 글래스모피즘 배경 렌더링 */}
      <canvas
        className="absolute w-full h-full blur-[20px] z-[-1] opacity-30 pointer-events-none -scale-y-100 left-0 top-0"
        style={backgroundStyle}
      />

      {location.pathname.includes("ordering_sentence") ? (
        <div className="flex flex-row justify-center items-center w-full gap-4">
          {!isStartSolvingQuiz && (
            <button onClick={handlePlay}>
              <img
                className="min-h-[124px]"
                src={`${process.env.PUBLIC_URL}/images/test/${
                  isPlay ? `waves_${currentWave}` : "play"
                }.png`}
                alt="Large White Speaker Icon"
              />
            </button>
          )}
          {isStartSolvingQuiz && !isCheckAnswer && (
            <button
              className="font-noto-sans-kr w-[124px] h-[124px] border bg-white text-2xl font-black rounded-[100%] border-solid border-white"
              onClick={handleCheckAnswer}
            >
              정답
              <br />
              확인
            </button>
          )}
          {isCheckAnswer && (
            <>
              <button
                className="font-noto-sans-kr w-[124px] h-[124px] border bg-white text-2xl font-black rounded-[100%] border-solid border-white"
                onClick={handleReset}
              >
                다시
                <br />
                풀기
              </button>
              <button
                className="font-noto-sans-kr w-[124px] h-[124px] border bg-white text-2xl font-black rounded-[100%] border-solid border-white"
                onClick={() => navigateToNext(location.pathname)}
              >
                {state.items[state.currentIndex + 1] ? (
                  <>
                    이동 <br /> 하기
                  </>
                ) : (
                  <>완료</>
                )}
              </button>
            </>
          )}
        </div>
      ) : (
        <>
          {!isCompleted && (
            <div className="flex flex-col justify-center items-center w-full">
              <button onClick={handlePlay}>
                <img
                  className="min-h-[124px]"
                  src={`${process.env.PUBLIC_URL}/images/test/${
                    isPlay ? `waves_${currentWave}` : "play"
                  }.png`}
                  alt="Large White Speaker Icon"
                />
              </button>
              <button
                className="ml-auto"
                onClick={(e) => handleShowSettings(e)}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/test/listening_setting.png`}
                  alt="Listening Settings Icon"
                />
              </button>
            </div>
          )}
          {isCompleted && (
            <>
              <button
                className="flex justify-center items-center w-full bg-[#40a0ff] text-2xl font-extrabold text-white text-center px-8 py-3 rounded-[100px] mb-4"
                onClick={handleReset}
              >
                한번 더 듣기
              </button>
              <button
                className="flex justify-center items-center w-full bg-[#40a0ff] text-2xl font-extrabold text-white text-center px-8 py-3 rounded-[100px]"
                onClick={() => navigateToNext(location.pathname)}
              >
                {state.items[state.currentIndex + 1] ? "다음 듣기" : "완료"}
              </button>
            </>
          )}
        </>
      )}
    </section>
  );
}
