import { tasksState, taskCompletionState } from "@states/progress";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { DailyTrainingState, ITrainingItem } from "@states/DailyTrainingState";
import { useEffect, useState } from "react";
import Utils from "@libs/utils";
import { TODAY_ROUTES } from "@constants/common.routes";
import TrainingCard from "../common/TrainingCard";

export default function MobileDailyTaskButton() {
  // const navigate = useNavigate();
  const [isDailyTrainingLoad, setIsDailyTrainingLoad] = useState(false);
  // const [taskList, setTaskList] = useRecoilState(tasksState);
  // const taskCompletion = useRecoilValue(taskCompletionState);
  const dtState = useRecoilValue(DailyTrainingState);

  useEffect(() => {
    if (dtState) {
      dtState.today.map((trainingItem: ITrainingItem) => {
        const { items } = trainingItem;
        if (items && items.length > 0) {
          setIsDailyTrainingLoad(true);
        } else {
          setIsDailyTrainingLoad(false);
        }
      });
    }
  }, [dtState]);

  // 카테고리 완료 상태 확인: 각 카테고리의 모든 하위 주제가 완료되었는지 확인
  // TODO: 다시 만들어야 함.
  // TODO: 완료 상태 확인
  // const isInProgress = items.length > progress && progress > -1;
  // const completed = items.length == progress;

  // const handleTaskClick = (task: Task) => {
  /** TODO: Progress 상태 업데이트 */
  // 페이지 이동 시, 상태 업데이트
  // setTaskList((prevTasks) =>
  //   prevTasks.map((t) =>
  //     t.name === task.name ? { ...t, isInProgress: true } : t
  //   )
  // );

  // 페이지 이동
  // navigate(task.to);
  // };

  return (
    <div className="grid justify-center items-center grid-rows-[repeat(2,1fr)] grid-cols-[repeat(2,1fr)] gap-4 w-full h-full max-xs:justify-items-center max-xs:gap-[16px_8px] max-xs:w-auto">
      {isDailyTrainingLoad &&
        dtState.today.map((trainingItem: ITrainingItem) => {
          const { type, items } = trainingItem;
          console.log("trainingItem", trainingItem);
          // TODO: 진행 & 완료 상태 확인
          // const isInProgress = items.length > progress && progress > -1;
          // 왜 complete이 2개?
          // const completed = items.length == progress;
          // const isComplete = isCategoryComplete(type.key, taskCompletion);

          const isInProgress = false;
          const completed = false;
          const isComplete = false;
          const bg = Utils.getTrainingTitleColorStr(type.key);
          const to = encodeURI(
            `${TODAY_ROUTES.MAIN}/${type.key.toLowerCase()}/${items[0].name}`
          );

          return (
            <TrainingCard
              type={type}
              key={type.key}
              to={to}
              completed={completed}
              isInProgress={isInProgress}
              bg={bg}
              items={items}
              isComplete={isComplete}
            />
          );
        })}
    </div>
  );
}
