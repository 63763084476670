import { useState, useEffect } from "react";

export default function useWaveAnimation(isPlay: boolean) {
  const [currentWave, setCurrentWave] = useState(1);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isPlay) {
      interval = setInterval(() => {
        setCurrentWave((prevWave) => (prevWave % 3) + 1);
      }, 300); // 300ms 간격으로 이미지 변경
    } else {
      setCurrentWave(1); // 재생이 끝나면 초기 이미지로 설정
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isPlay]);

  return currentWave;
}
