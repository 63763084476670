import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { supabase } from "@libs/supabaseClient";
import { TrainingGroupType } from "@libs/common.types";

/**
 * 커스텀 훅: `useFetchTrainingData`
 *
 * 훈련별 듣기 컴포넌트에서 사용되는 훅
 * 훈련 데이터를 가져오고 변환하는 로직을 담당
 * 주어진 훈련 유형에 따라 데이터를 가져와 변환된 형태로 반환
 *
 */
export default function useFetchTrainingData() {
  const location = useLocation();
  const [trainingGroup, setTrainingGroup] = useState<TrainingGroupType[]>([]);

  // 데이터 가져오기
  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await supabase
        .from("training_group")
        .select("*")
        .eq("training_type", location.state.type)
        .order("id", { ascending: true });

      if (error) {
        console.error("에러 발생:", error);
      } else {
        setTrainingGroup(data);
      }
    };

    fetchData();
  }, [location.state.type]);

  // 데이터 변환 함수
  const transformData = () => {
    if (!trainingGroup || trainingGroup.length === 0) return null;

    // 모든 items를 하나의 배열로 합치기
    const allItems = trainingGroup.flatMap((group) => ({
      id: group.id,
      name: group.name,
      category_main_code: group.category_main_code,
      category_sub_code: group.category_sub_code
    }));

    return {
      type: trainingGroup[0].training_type,
      items: allItems
    };
  };

  // 변환된 데이터를 반환
  const transformedData = transformData();

  return { transformedData };
}
