type TestPageHeaderProps = {
  title: string;
  navigateToBack: () => void;
};

export default function TestPageHeader({
  title,
  navigateToBack
}: TestPageHeaderProps) {
  return (
    // 헤더 영역
    <div className="flex justify-between items-center text-[18px] text-[#0d1b34] p-[30px] border-b-[rgba(0,122,255,0.15)] border-b border-solid max-sm:p-[18px]">
      <button
        className="font-noto-sans-kr text-[18px] font-normal underline max-sm:text-lg"
        onClick={navigateToBack}
      >
        &lt; 뒤로가기
      </button>
      <p className="text-[26px] font-extrabold max-sm:text-[20px]">{title}</p>
    </div>
  );
}
