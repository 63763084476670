import { useState } from "react";
import { Link } from "react-router-dom";

export default function Header({ className = "" }: any) {
  const [isClicked, setIsClicked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const handleMouseDown = () => setIsClicked(true);
  const handleMouseUp = () => setIsClicked(false);

  return (
    <>
      <header
        className={`w-full h-[75px] bg-[#fff] font-bold max-sm:w-full max-sm:h-[95px] max-sm:bg-white max-sm:p-5 max-sm:border-b-[0.333px] max-sm:border-b-[rgba(0,0,0,0.3)] max-sm:border-solid ${className}`}
      >
        <div className="flex justify-between items-center max-w-[1440px] h-full mx-auto my-0 px-[30px] py-0 max-sm:max-w-full max-sm:p-0">
          <div className="h-full pt-3 pb-0 px-0 max-sm:p-0">
            <Link
              to="/main"
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img
                className="h-full"
                src={`${process.env.PUBLIC_URL}/images/logo/header_logo_new.png`}
                alt="Header Logo"
              />
            </Link>
          </div>
          <div className="flex font-semibold text-[#4894fe] whitespace-nowrap">
            <NavLink
              to="/main/myPage"
              defaultIcon="icon_profile"
              clickedIcon="icon_profile_white"
              children="사용자 정보"
            />
            {/* 설정 삭제 : 사용자 정보 내부 또는 추가 기획 필요.
            <NavLink
              to="/main/settings"
              defaultIcon="icon_setting"
              clickedIcon="icon_setting_white"
              children="설정"
            /> */}
          </div>
        </div>
      </header>
    </>
  );
}

{
  /** 글로벌 모달 삭제 추가 기획 및 디자인 필요 */
}

type NavLinkProps = {
  to: string;
  defaultIcon: string;
  clickedIcon: string;
  children: string;
};
function NavLink({ to, defaultIcon, clickedIcon, children }: NavLinkProps) {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const handleMouseDown = () => setIsClicked(true);
  const handleMouseUp = () => setIsClicked(false);

  return (
    <Link
      className="flex justify-center items-center transition-all duration-[0.2s] ease-[ease-out] mx-2.5 my-0 px-5 py-[5px] rounded-[25px] max-sm:m-0 max-sm:p-0"
      to={to}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      style={{
        backgroundColor: isClicked
          ? "#40A0FF"
          : isHovered
          ? "#fff"
          : "transparent",
        color: isClicked ? "#fff" : "inherit"
      }}
    >
      <img
        className="ml-0 mr-[5px] my-0 max-sm:m-0"
        src={
          isClicked
            ? `${process.env.PUBLIC_URL}/images/icons/${clickedIcon}.png`
            : `${process.env.PUBLIC_URL}/images/icons/${defaultIcon}.png`
        }
        alt="Nav Icon"
      />
      <p className="max-sm:hidden">{children}</p>
    </Link>
  );
}
