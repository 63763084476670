const MainFooter = () => {
  return (
    <footer className="font-pretendard-variable absolute z-[999] flex flex-col w-screen mx-auto my-0 bottom-16 max-sm:bottom-[10px] max-sm:text-[10px]">
      <div className="text-center text-white">
        <span className="text-[1em] leading-[2.4em]">Speech Ear</span>
      </div>
      <div className="text-center text-white">
        <span className="text-[1.2em]">ⓒ iHAB, Inc. all rights reserved.</span>
      </div>
    </footer>
  );
};

export default MainFooter;
