import { useState } from "react";

import ListeningLinks from "@components/mobile/ListeningLinks";
import Pagination from "@components/common/Pagination";
import { ListeningItem, TrainingGroupItemType } from "@libs/common.types";
import useFetchTrainingData from "@hooks/useFetchTrainingData";

export default function ListeningByTraining() {
  // 페이지네이션 관련 상태
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // 훈련별 듣기 데이터를 가져오고 변환(useFetchTrainingData)
  const { transformedData } = useFetchTrainingData();

  // 페이지에 따라 보여줄 아이템 계산
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = transformedData?.items.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  return (
    <div className="relative bg-[#fff] w-full h-[calc(100vh_-_75px)] pt-[30px] pb-0 px-0 max-sm:h-screen max-sm:overflow-x-hidden max-sm:pt-0">
      <div className="max-w-[1440px] h-auto mx-auto my-0 pt-0 pb-[30px] px-[30px] max-sm:h-auto max-sm:p-[30px]">
        <div className="font-noto-sans-kr flex justify-center items-center gap-4 w-full text-[#0d1b34] mt-0 mb-4 mx-0 max-sm:justify-between max-sm:gap-0">
          <p className="text-[32px] font-black tracking-[1px]">훈련별 듣기</p>
        </div>
        <p className="font-medium text-[#8696bb] text-center mt-0 mb-3 mx-0 max-sm:text-left">
          주제를 선택하여 듣기 연습을 진행하세요
        </p>

        {transformedData && (
          <ListeningLinks
            type={transformedData.type}
            items={currentItems as ListeningItem[]}
            categoryData={transformedData.items.map((item) => ({
              p_code: item.category_main_code,
              code: item.category_sub_code
            }))}
            page={currentPage}
          />
        )}

        <Pagination
          transformedData={transformedData as TrainingGroupItemType}
          itemsPerPage={itemsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
}
