import { User } from '@supabase/supabase-js';
import { atom } from 'recoil';
import { recoilPersist } from "recoil-persist";
import { Tables } from '@libs/supabase.types';
const { persistAtom } = recoilPersist()

export type UserAuthType = Pick<User, 
  'id' | 'email' | 'user_metadata' | 'aud' | 'last_sign_in_at' | 'updated_at' | 'created_at'>;

interface IUserAuthState {
  user: UserAuthType | null;
  profile: Tables<'profiles'> | null;
  isAuthenticated: boolean;
}

export const UserAuthState = atom({
  key: 'authState',
  default: {
    user: null,
    profile: null,
    isAuthenticated: false,
  } as IUserAuthState,
  effects_UNSTABLE: [persistAtom],
});