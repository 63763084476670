import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { Link, useLocation, useParams } from "react-router-dom";

import { ROUTES } from "@constants/common.routes";

import { CategoryMainState } from "@states/DataTypeState";

import useAppData from "@hooks/useAppData";
import Utils from "@libs/utils";

import type { Tables } from "@libs/supabase.types";

type Refs = {
  [key: string]: MutableRefObject<HTMLAnchorElement | null>;
};

export default function TopicListeningSelect() {
  const location = useLocation();
  const categoryMainState = useRecoilValue(CategoryMainState);
  const { data } = categoryMainState;
  const { main } = useParams<{ main?: string }>();

  const [currentCategoryMain, setCurrentCategoryMain] = useState();

  // move to Utils
  // const { addSpacingToWords } = Utils;

  useEffect(() => {
    if (main && data && data.length > 0) {
      setCurrentCategoryMain(data.find((d: any) => d.route === main) || null);
    }
  }, [main, data]);

  // 각 아이템에 대한 ref 배열을 생성

  const refs: Refs = data.reduce((acc: Refs, value: any) => {
    acc[value.route] = useRef<HTMLAnchorElement>(null);
    return acc;
  }, {});

  // 활성화된 아이템이 변경될 때 스크롤 이동
  useEffect(() => {
    const activeTopic = location.pathname.split("/").pop() || ""; // 빈 문자열로 기본값 설정
    const activeRef = refs[activeTopic];
    if (activeRef && activeRef.current) {
      activeRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center"
      });
    }
  }, [location, refs]);

  return (
    <div className="bg-[#fff] w-full h-[calc(100vh_-_75px)] pt-[30px] pb-0 px-0 max-sm:h-screen max-sm:overflow-x-hidden max-sm:p-0">
      <div className="max-w-[1440px] h-auto mx-auto my-0 pt-0 pb-[30px] px-[30px] max-sm:h-auto max-sm:p-[30px]">
        <div className="font-noto-sans-kr flex justify-center items-center gap-4 w-full text-[#0d1b34] mt-0 mb-4 mx-0 max-sm:justify-between max-sm:gap-0">
          <p className="text-[32px] font-black tracking-[1px]">주제별 듣기</p>
        </div>
        <p className="font-medium text-[#8696bb] text-center mt-0 mb-3 mx-0 max-sm:text-left">
          원하는 주제를 선택하세요
        </p>

        <div className="flex justify-center items-center gap-6 w-full whitespace-nowrap mx-0 my-5 px-0 py-4 max-sm:overflow-x-scroll max-sm:justify-start">
          <CategoryMainSmallList data={data} refs={refs} />
        </div>

        <div className="flex flex-col justify-center items-center gap-6">
          <CategorySubList currentCategoryMain={currentCategoryMain} />
        </div>
      </div>
    </div>
  );
}

const CategoryMainSmallList = (props: any) => {
  const { data, refs } = props;
  if (data && data.length > 0) {
    return data.map((item: any) => {
      const isActive = location.pathname.includes(item.route);
      const imageSuffix = isActive ? "" : "_off";

      return (
        <Link
          ref={refs[item.route]}
          to={`${ROUTES.CATEGORY}/${item.route}`}
          className="flex justify-center items-center gap-1.5 text-[20px] font-semibold text-[#4894fe]"
          key={item.route}
        >
          <img
            className="max-w-min"
            src={`${process.env.PUBLIC_URL}/images/icons/topic/icon_${item.route}_sm${imageSuffix}.png`}
            alt={`${item.route} Icon`}
          />
          {isActive && <p>{item.name}</p>}
        </Link>
      );
    });
  } else {
    return <></>;
  }
};

const CategorySubList = (props: any) => {
  const { getCategorySubList } = useAppData();
  const { currentCategoryMain } = props;
  const [categorySubList, setCategorySubList] = useState<
    Tables<"category_sub">[]
  >([]);

  useEffect(() => {
    if (currentCategoryMain && currentCategoryMain.code) {
      getCategorySubList(currentCategoryMain.code).then((data) => {
        setCategorySubList(data);
      });
    }
  }, [currentCategoryMain]);

  if (currentCategoryMain && categorySubList && categorySubList.length > 0) {
    const listElem = categorySubList.map((item: Tables<"category_sub">) => {
      console.log("item", item);
      return (
        <CategorySubButton
          key={item.code}
          main={currentCategoryMain}
          sub={item}
        />
      );
    });
    return <>{listElem}</>;
  } else {
    return <></>;
  }
};

type CategorySubButtonProps = {
  main: Tables<"category_main">;
  sub: Tables<"category_sub">;
};

function CategorySubButton({ main, sub }: CategorySubButtonProps) {
  // URL 친화적인 문자열로 인코딩
  // const topicUrl = encodeURIComponent(sub.route);
  const [bookmark, setBookmark] = useState(false);
  // const imageFileName = "bg_korea_landmarks"; // 예제에서는 모든 버튼에 동일한 이미지를 사용
  const { addSpacingToWords } = Utils; // 카테고리명 공백 추가 함수

  // 대분류 코드를 소문자로 변환하여 폴더명으로 사용
  const categoryFolder = main.code.toLowerCase();
  const imageFileName = sub.code.toLowerCase();
  const backgroundUrl = `url(${process.env.PUBLIC_URL}/images/backgrounds/${categoryFolder}/${imageFileName}.jpg)`;

  return (
    // 2024-06-04 경로 수정
    <Link
      to={sub.route}
      state={{
        ...sub,
        backgroundImage: backgroundUrl
      }}
      className="relative flex flex-col justify-start items-start gap-2 w-full h-full bg-[rgba(0,0,0,0.5)] bg-blend-overlay bg-[center_center] bg-cover text-white text-[36px] font-semibold text-center transition-all duration-[0.3s] ease-[ease] p-10 rounded-[32px] hover:bg-[rgba(0,0,0,0.25)] hover:scale-[1.015] max-sm:p-6"
      style={{
        backgroundImage: backgroundUrl
      }}
    >
      <button
        className="absolute p-6 right-0 top-0"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setBookmark((prev) => !prev);
        }}
      >
        <img
          className="w-[15px]"
          src={`${process.env.PUBLIC_URL}/images/icons/icon_bookmark_white${
            bookmark ? "_fill" : ""
          }.png`}
          alt="Bookmark Icon"
        />
      </button>

      <p className="w-max bg-white text-[20px] text-[#191919] px-[12px] py-[6px] rounded-[100px]">
        <span>{main.name}</span>
      </p>
      <p className="max-sm:text-[1.8rem]">{addSpacingToWords(sub.name)}</p>
    </Link>
  );
}
