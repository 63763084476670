import { useState, useEffect } from "react";

export default function useAudioSettings() {
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [shouldRender, setShouldRender] = useState<boolean>(false);

  const handleShowSettings = () => {
    setShowSettings((prev) => !prev);
  };
  const handleStopBubble = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (showSettings) {
      setShouldRender(true);
    } else {
      const timer = setTimeout(() => {
        setShouldRender(false);
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [showSettings]);

  return {
    showSettings,
    shouldRender,
    handleShowSettings,
    handleStopBubble
  };
}
