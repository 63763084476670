import { Link } from "react-router-dom";
import { TrainingCardProps } from "src/types/props.types";

export default function TrainingCard({
  type,
  to,
  completed,
  isInProgress,
  bg,
  items,
  isComplete
}: TrainingCardProps) {
  return (
    <Link
      key={type.key}
      to={to}
      className={`flex flex-col justify-center items-center w-full h-36 p-4 rounded-[20px] cursor-pointer max-xs:justify-between ${
        completed ? "completed" : ""
      } ${isInProgress ? "in-progress" : ""} ${
        bg === "blue" ? "bg-blueMain" : ""
      } ${bg === "magenta" ? "bg-magentaMain" : ""} ${
        bg === "purple" ? "bg-purpleMain" : ""
      } ${bg === "mint" ? "bg-peacockMain" : ""}`}
      state={{
        currentIndex: 0,
        items: items,
        type: type.key,
        title: type.name,
        categoryData: {
          p_code: items[0].category_main_code,
          code: items[0].category_sub_code
        }
      }}
      // onClick={() => handleTaskClick(task)}
    >
      <p className="font-noto-sans-kr font-semibold text-[20px] text-white mt-0 mb-4 mx-0 max-sm:w-full max-sm:break-keep max-sm:text-center max-xs:basis-14 max-xs:m-0">
        {type.name}
      </p>
      <div className="w-full bg-white text-center p-[12px] rounded-[20px] max-xs:px-6 max-xs:py-3">
        <p className="flex justify-center items-center whitespace-nowrap text-[16px] font-semibold text-[#40a0ff] font-noto-sans-kr max-sm:justify-evenly">
          {isComplete ? (
            <>
              <p>완료</p>
              <img
                className="ml-2 mr-0 my-0 max-sm:m-0"
                src={`${process.env.PUBLIC_URL}/images/icons/icon_task_complete_blue.png`}
                alt="Task Complete Icon"
              />
            </>
          ) : isInProgress ? (
            "이어듣기"
          ) : (
            "시작"
          )}
        </p>
      </div>
    </Link>
  );
}
