import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="max-w-[1440px] h-full text-center break-keep mx-auto my-0 p-[30px]">
      <div>
        <p className="text-[128px] font-black text-[#40a0ff] max-sm:text-[82px]">
          404
        </p>
        <p className="text-[96px] font-semibold my-[30px] max-sm:text-[66px]">
          잘못된 접근입니다!
        </p>
      </div>
      <div className="flex justify-center items-center text-xl font-semibold text-[#40a0ff] text-center border-solid border-[3px] border-[#40a0ff] rounded-[100px]">
        <Link to="/main" className="w-full p-3">
          홈으로 이동
        </Link>
      </div>
    </div>
  );
}
