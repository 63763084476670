import { ITrainingResponseRecord } from "@libs/common.types";

type QuestionBottomProps = {
  progress: number;
  resp: ITrainingResponseRecord;
  selAnswer: string[];
  handleAnswer: (answer: string[], progress: number) => void;
};

export default function QuestionBottom({
  progress,
  resp,
  selAnswer,
  handleAnswer
}: QuestionBottomProps) {
  const currentResp = resp[progress];

  if (!currentResp && progress === 1) {
    return (
      <button
        className="flex justify-center items-center min-w-[80px] text-xl font-semibold break-keep bg-white px-8 py-3 rounded-[100px] mt-[12px]"
        onClick={() => handleAnswer(selAnswer, progress)}
      >
        정답확인
      </button>
    );
  } else {
    return <></>;
  }
}
