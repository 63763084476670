import { ROUTES } from "@constants/common.routes";
import { Link } from "react-router-dom";

type MobileMainNavigatorType = {
  sectionTitle: string;
  sectionGuideText: string;
  children: any;
};

type TitleToPathMap = {
  [key: string]: string;
};

export default function MobileMainNavigator({
  sectionTitle,
  sectionGuideText,
  children
}: MobileMainNavigatorType) {
  // 2024-04-22 sectionTitle에 따라 동적으로 경로를 설정합니다.
  // TODO: 주제별 듣기 링크 추가
  const titleToPath: TitleToPathMap = {
    "오늘의 듣기": `${ROUTES.TODAY.MAIN}`,
    가로세로퀴즈: `${ROUTES.CROSSWORD}`
  };
  const linkPath = titleToPath[sectionTitle];

  return (
    <div className="flex flex-col justify-center items-center w-full border mx-0 my-5 p-[30px] rounded-xl border-solid border-[rgba(0,122,255,0.15)] max-sm:p-4 max-sm:last:mt-5 max-sm:last:mb-0 max-sm:last:mx-0">
      <Link
        to={linkPath}
        className="flex justify-center items-center w-full border-b-neutral-100 font-black text-[32px] text-center tracking-[1px] text-[#0d1b34] mt-0 mb-4 mx-0 pt-0 pb-4 px-0 border-b border-solid"
      >
        {sectionTitle === "가로세로퀴즈" ? (
          <>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/icon_crossword.png`}
              alt="Crossword Icon"
              style={{ margin: "0 8px 0 0" }}
            />
            가로세로퀴즈
          </>
        ) : (
          sectionTitle
        )}
      </Link>
      <p className="text-[#8696bb] mt-0 mb-4 mx-0 break-keep text-center font-noto-sans-kr">
        {sectionGuideText}
      </p>
      {children}
    </div>
  );
}
