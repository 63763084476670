import { ITrainingResponseRecord } from "@libs/common.types";

type QuestionHeaderProps = {
  progress: number;
  resp: ITrainingResponseRecord;
  isSolvingQuiz: boolean;
  message: string;
};

export default function QuestionHeader({
  progress,
  resp,
  isSolvingQuiz,
  message
}: QuestionHeaderProps) {
  const currentResp = resp[progress];
  const fontSize =
    currentResp && currentResp.response === "correct" ? "36" : "24";

  return (
    <p
      className={`font-noto-sans-kr font-semibold text-white text-center break-keep mt-0 mb-3 mx-0 text-[${fontSize}px] ${
        isSolvingQuiz ? "" : "underline"
      } `}
    >
      {message}
    </p>
  );
}
