import { useMemo } from "react";

export default function useBackgroundStyle(
  p_code: string,
  code: string,
  isCompleted: boolean
) {
  const backgroundUrl = useMemo(
    () =>
      `url(${
        process.env.PUBLIC_URL
      }/images/backgrounds/${p_code.toLowerCase()}/${code.toLowerCase()}.jpg)`,
    [p_code, code]
  );

  const backgroundStyle = useMemo(
    () =>
      !isCompleted
        ? {
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), ${backgroundUrl}`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
          }
        : {
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), ${backgroundUrl}`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
          },
    [backgroundUrl, isCompleted]
  );

  return backgroundStyle;
}
