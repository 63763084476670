import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, SubmitHandler, SubmitErrorHandler } from "react-hook-form";
import { useRecoilValue } from "recoil";

import { AuthError } from "@supabase/supabase-js";

import MainFooter from "@components/common/MainFooter";

import useAuth from "@hooks/useAuth";

import type { AuthInputType } from "@libs/common.types";

import { UserAuthState } from "@states/UserAuthState";

export default function Login() {
  const auth = useAuth();
  const navigate = useNavigate();
  const uaState = useRecoilValue(UserAuthState);

  const [emailErrorMessage, setEmailErrorMessage] = useState<
    string | undefined | null
  >();
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<
    string | undefined | null
  >();

  useEffect(() => {
    console.log(uaState);
    if (uaState.user && uaState.profile && uaState.isAuthenticated) {
      navigate("/main");
    }
  }, [uaState.user && uaState.profile && uaState.isAuthenticated, navigate]);

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { isSubmitting }
  } = useForm<AuthInputType>();

  const onSubmit: SubmitHandler<AuthInputType> = async (data) => {
    console.log("onSubmit", data);
    setEmailErrorMessage(null);
    setPasswordErrorMessage(null);
    try {
      const result = await auth.signIn(data);
      console.log("onSubmit::result", result);
    } catch (error) {
      const err = error as AuthError | Error;
      console.error("onSubmit::error", err.message);
      if (err.message === "Email not confirmed") {
        alert("이메일 인증을 완료해주세요.");
      } else {
        alert(
          "로그인 오류가 발생하였습니다.\n이메일 또는 비밀번호를 확인하여 주세요."
        );
      }
      // TODO : more specific error exception handle
    }
  };

  const onError: SubmitErrorHandler<AuthInputType> = (error) => {
    if (Object.keys(error).includes("email")) {
      setEmailErrorMessage(error.email?.message);
      setPasswordErrorMessage(null);
      setFocus("email");
    } else if (Object.keys(error).includes("password")) {
      setEmailErrorMessage(null);
      setPasswordErrorMessage(error.password?.message);
      setFocus("password");
    }
  };

  return (
    <>
      <div className="font-pretendard-variable leading-none overflow-hidden">
        <div className="bg-login-gradient justify-end absolute overflow-hidden w-screen h-screen flex items-center top-0 z-[1] max-sm:relative max-sm:flex max-sm:flex-col max-sm:justify-start max-sm:items-center">
          <div className="absolute top-0 left-0 -z-[1] max-sm:-top-24">
            <img
              className="block w-full max-sm:hidden"
              src={`${process.env.PUBLIC_URL}/images/logo/circle_logo.png`}
              alt="CircleLogo"
            />
            <img
              className="hidden max-sm:block max-sm:w-full"
              src={`${process.env.PUBLIC_URL}/images/logo/circle_logo_center.png`}
              alt="CircleLogo_Mob"
            />
          </div>
          <div className="h-screen flex items-center flex-col justify-center mr-[162px] max-sm:mx-auto max-sm:my-0">
            <form
              id="loginForm"
              className="max-sm:w-full max-sm:mt-[20vh]"
              onSubmit={handleSubmit(onSubmit, onError)}
            >
              <div className="relative block m-0 p-0">
                <input
                  type="email"
                  className="placeholder:text-white autofill-bg placeholder-white h-12 w-[366px] font-medium text-[1.6em] leading-[22px] text-white bg-transparent transition-all duration-200 ease-linear mb-2.5 pl-11 pr-0 pt-2 pb-0 border-b-2 border-b-[rgba(255,255,255,0.35)] border-solid outline-none focus:outline-none focus:border-b-2 focus:border-b-white focus:border-solid active:border-b-2 active:border-b-white active:outline-none active:border-solid max-sm:w-[240px] max-sm:text-[18px]"
                  placeholder="이메일"
                  aria-invalid={emailErrorMessage ? "true" : "false"}
                  {...register("email", {
                    required: "이메일은 필수 입력 항목입니다.",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                      message: "이메일 형식이 아닙니다."
                    }
                  })}
                />
                <i className="absolute h-6 w-6 transition-all duration-200 ease-linear bg-[24px] left-1.5 top-[19px] icon_account"></i>
              </div>
              {emailErrorMessage && (
                <div className="w-[366px] bg-white text-center break-keep mt-[15px] mb-2.5 p-[15px] rounded-[10px] max-sm:w-[240px]">
                  <span className="text-[1.2em] font-semibold text-[#ff0000] max-sm:text-[1em]">
                    {emailErrorMessage}
                  </span>
                </div>
              )}
              <div className="relative block m-0 p-0">
                <input
                  type="password"
                  className="placeholder:text-white autofill-bg placeholder-white h-12 w-[366px] font-medium text-[1.6em] leading-[22px] text-white bg-transparent transition-all duration-200 ease-linear mb-2.5 pl-11 pr-0 pt-2 pb-0 border-b-2 border-b-[rgba(255,255,255,0.35)] border-solid outline-none focus:outline-none focus:border-b-2 focus:border-b-white focus:border-solid active:outline-none  active:border-b-2 active:border-b-white active:border-solid max-sm:w-[240px] max-sm:text-[18px]"
                  placeholder="비밀번호"
                  aria-invalid={passwordErrorMessage ? "true" : "false"}
                  {...register("password", {
                    required: "비밀번호는 필수 입력 항목입니다.",
                    minLength: {
                      value: 8,
                      message: "비밀번호는 8자 이상이여야 합니다."
                    }
                  })}
                />
                <i className="absolute h-6 w-6 transition-all duration-200 ease-linear bg-[24px] left-1.5 top-[19px] icon_password"></i>
              </div>
              {passwordErrorMessage && (
                <div className="w-[366px] bg-white text-center break-keep mt-[15px] mb-2.5 p-[15px] rounded-[10px] max-sm:w-[240px]">
                  <span className="text-[1.2em] font-semibold text-[#ff0000] max-sm:text-[1em]">
                    {passwordErrorMessage}
                  </span>
                </div>
              )}
              <div className="text-center mt-16 max-sm:my-[20px]">
                {!isSubmitting ? (
                  <button
                    type="submit"
                    className="bg-white inline-block box-border text-[#545454] cursor-pointer opacity-100 select-none touch-manipulation w-[222px] text-[1.6em] font-semibold px-[18px] py-4 rounded-[38px] border-0 max-sm:w-[195px] max-sm:h-[50px] max-sm:text-[21px] max-sm:content-center max-sm:p-2.5"
                  >
                    로그인
                  </button>
                ) : (
                  <button
                    disabled
                    type="button"
                    className="bg-white inline-block box-border text-[#545454] cursor-pointer opacity-100 select-none touch-manipulation w-[222px] text-[1.6em] font-semibold px-[18px] py-4 rounded-[38px] border-0 max-sm:w-[195px] max-sm:h-[50px] max-sm:text-[21px] max-sm:content-center max-sm:p-2.5"
                  >
                    로그인중...
                  </button>
                )}
              </div>
              <div className="text-center mt-16 max-sm:mt-8">
                {/* 
                // TODO: 비밀번호 찾기 기능 추가 필요
                <div>
                  <span>
                    <Link to="/">비밀번호를 잊으셨습니까?</Link>
                  </span>
                </div> 
                */}
                <div className="pb-9 max-sm:mb-5 max-sm:p-0">
                  <span>
                    <Link
                      to="/signup"
                      className="text-[1em] text-white pb-1 border-b-white border-b border-solid"
                    >
                      계정만들기
                    </Link>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <MainFooter />
      </div>
    </>
  );
}
