import { atom } from 'recoil';
import { recoilPersist } from "recoil-persist";
import { Tables } from '@libs/supabase.types';
const { persistAtom } = recoilPersist()

interface ICategoryMainState {
  data: Tables<'category_main'> | [];
}

export const CategoryMainState = atom({
  key: 'categoryMainState',
  default: {
    data: [],
  } as ICategoryMainState,
  effects_UNSTABLE: [persistAtom],
});