import { useEffect } from "react";
import { UserAuthState } from "@states/UserAuthState";
import { useRecoilValue } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";

import Header from "@components/common/Header";
import { TrainingType } from "@constants/trainingType";
import useAppData from "@hooks/useAppData";

export default function Navigations({ children }: any) {
  const navigate = useNavigate();
  const location = useLocation();

  const { user, isAuthenticated } = useRecoilValue(UserAuthState);
  const isUserAuthenticated = user && isAuthenticated;

  const { getTodayTrainingLog } = useAppData();

  useEffect(() => {
    if (!isUserAuthenticated) {
      alert("로그인이 필요한 페이지입니다.");
      navigate("/login");
    } else {
      getTodayTrainingLog();
    }
  }, []);

  // 검사 페이지에서는 Header를 렌더링하지 않음
  const pathsWithoutHeader = [
    TrainingType.LISTEN_IN_NOISE.toLowerCase(),
    TrainingType.SHORT_STORY.toLowerCase(),
    TrainingType.LONG_STORY.toLowerCase(),
    TrainingType.ORDERING_SENTENCE.toLowerCase()
  ];

  const isPathWithoutHeader = pathsWithoutHeader.some((path) =>
    location.pathname.includes(path)
  );

  // 경로가 /listening_by_training/를 포함하면 헤더를 렌더링
  const shouldRenderHeader =
    !isPathWithoutHeader ||
    location.pathname.includes("/listening_by_training/");

  // TODO: 로그인 중 ?? 로그인 실패 ?? 데이터 로딩 중 ?? 데이터 로딩 완료
  return !isAuthenticated ? null : (
    <>
      {shouldRenderHeader && <Header />}
      {children}
    </>
  );
}
