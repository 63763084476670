import { ITrainingType } from "@libs/common.types";

// TODO: Load from database?
export const TrainingType = {
  LISTEN_IN_NOISE: "LISTEN_IN_NOISE",
  SHORT_STORY: "SHORT_STORY",
  LONG_STORY: "LONG_STORY",
  ORDERING_SENTENCE: "ORDERING_SENTENCE",
  CROSSWORD: "CROSSWORD"
};

export const TrainingTypes: ITrainingType[] = [
  {
    index: 1,
    key: TrainingType.LISTEN_IN_NOISE,
    name: "소음 하 문장듣기",
    isCategory: true
  },
  {
    index: 2,
    key: TrainingType.SHORT_STORY,
    name: "짧은 이야기 듣기",
    isCategory: true
  },
  {
    index: 3,
    key: TrainingType.LONG_STORY,
    name: "긴 이야기 듣기",
    isCategory: true
  },
  {
    index: 4,
    key: TrainingType.ORDERING_SENTENCE,
    name: "문장 순서화 하기",
    isCategory: true
  },
  {
    index: 5,
    key: TrainingType.CROSSWORD,
    name: "가로세로 퀴즈",
    isCategory: false
  }
];
