import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PaginationProps } from "src/types/props.types";

export default function Pagination({
  transformedData,
  itemsPerPage,
  setCurrentPage,
  currentPage
}: PaginationProps) {
  const location = useLocation();

  const [currentPageGroup, setCurrentPageGroup] = useState(1);
  const pagesPerGroup = 10;
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (location.state?.categoryData?.page) {
      const pageFromState = location.state.categoryData.page;
      setCurrentPage(pageFromState);

      // 페이지에 따라 currentPageGroup을 설정
      const group = Math.ceil(pageFromState / pagesPerGroup);
      setCurrentPageGroup(group);
    }
  }, [location.state, setCurrentPage, setCurrentPageGroup]);

  // 전체 페이지 수 계산
  const totalPages = transformedData
    ? Math.ceil(transformedData.items.length / itemsPerPage)
    : 1;
  const totalPageGroups = Math.ceil(totalPages / pagesPerGroup);
  const startPage = (currentPageGroup - 1) * pagesPerGroup + 1;
  const endPage = Math.min(startPage + pagesPerGroup - 1, totalPages);

  const goToNextGroup = () => {
    setCurrentPageGroup((prevGroup) =>
      Math.min(prevGroup + 1, totalPageGroups)
    );
    setCurrentPage((currentPageGroup + 1 - 1) * pagesPerGroup + 1);
  };

  const goToPreviousGroup = () => {
    setCurrentPageGroup((prevGroup) => Math.max(prevGroup - 1, 1));
    setCurrentPage((currentPageGroup - 1 - 1) * pagesPerGroup + 1);
  };

  const goToPage = (page: number) => {
    // 페이지가 범위를 벗어나지 않도록 안전하게 설정
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    } else {
      setCurrentPage(1); // 기본값으로 1 설정
    }
  };

  const handleGoToPageInput = (
    event:
      | React.KeyboardEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement>
  ) => {
    const page = parseInt(event.currentTarget.value, 10);
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      setCurrentPageGroup(Math.ceil(page / pagesPerGroup));
      event.currentTarget.blur();
    } else {
      setCurrentPage(1); // 잘못된 값일 경우 기본값 1로 설정
      setCurrentPageGroup(1);
      event.currentTarget.blur();
    }
  };

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className="absolute bottom-[15px] w-[calc(100%_-_60px)] max-w-[1380px] my-0 mx-auto grid justify-center sm:flex sm:items-center gap-1">
      <nav className="flex items-center gap-x-1" aria-label="Pagination">
        <button
          type="button"
          className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg border border-transparent text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
          aria-label="Previous"
          onClick={goToPreviousGroup}
          disabled={currentPageGroup === 1}
        >
          <svg
            className="shrink-0 size-3.5"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="m15 18-6-6 6-6"></path>
          </svg>
          <span className="sr-only">Previous</span>
        </button>
        <div className="flex items-center gap-x-1">
          {Array.from(
            { length: endPage - startPage + 1 },
            (_, i) => startPage + i
          ).map((page) => (
            <button
              key={page}
              type="button"
              className={`min-h-[38px] min-w-[38px] flex justify-center items-center border ${
                currentPage === page
                  ? "border-gray-200 bg-gray-200"
                  : "border-transparent"
              } text-gray-800 hover:bg-gray-100 py-2 px-3 text-sm rounded-lg focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none`}
              aria-current={currentPage === page ? "page" : undefined}
              onMouseDown={() => goToPage(page)}
            >
              {page}
            </button>
          ))}
        </div>
        <button
          type="button"
          className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg border border-transparent text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
          aria-label="Next"
          onClick={goToNextGroup}
          disabled={currentPageGroup === totalPageGroups}
        >
          <span className="sr-only">Next</span>
          <svg
            className="shrink-0 size-3.5"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="m9 18 6-6-6-6"></path>
          </svg>
        </button>
      </nav>

      {/* Go to 기능 */}
      <div className="flex justify-center sm:justify-start items-center gap-x-2">
        <div className="relative">
          <input
            type="number"
            pattern="[0-9]+"
            className="min-h-[38px] py-2 px-2.5 block w-12 border-2 border-gray-400 ring-gray-400 rounded-lg text-sm text-center focus:border-blue-500 focus:ring-blue-500 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none disabled:opacity-50 disabled:pointer-events-none"
            defaultValue={1}
            style={{ appearance: "textfield" }}
            onBlur={handleGoToPageInput}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleGoToPageInput(e);
            }}
            onMouseEnter={handleMouseEnter} // 마우스를 올렸을 때 툴팁 표시
            onMouseLeave={handleMouseLeave} // 마우스를 떠났을 때 툴팁 숨김
          />
          {showTooltip && (
            <div className="absolute top-[-45px] w-[216px] h-full left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-sm leading-4 rounded py-1 px-2 shadow-lg">
              페이지 번호 입력 후 엔터를 누르거나 아무 곳이나 클릭하세요
            </div>
          )}
        </div>
        <span className="text-sm text-gray-800 whitespace-nowrap">
          페이지로 이동
        </span>
      </div>
    </div>
  );
}
