import { Route, Routes } from "react-router-dom";

import LayoutRoutes from "./LayoutRoutes";
import Main from "@pages/Main";
import Login from "@pages/Login";
import Signup from "@pages/Signup";

import NotFound from "@pages/NotFound";

export default function MainRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/main/*" element={<LayoutRoutes />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
