import { TrainingItemType } from "@libs/common.types";
import { Fragment } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from "react-beautiful-dnd";

type Result = {
  isCorrect: boolean;
  correctIndex: number;
};

type SentenceOrderingQuizContentProps = {
  isStartSolvingQuiz: boolean;
  isSolved: boolean;
  correctCount: number | null;
  trainingItems: TrainingItemType[] | undefined;
  message: string;
  onDragEnd: (result: DropResult) => void;
  getItemStyle: (isDragging: boolean, draggableStyle: any) => any;
  results: Result[];
};

export default function SentenceOrderingQuizContent({
  isStartSolvingQuiz,
  isSolved,
  correctCount,
  trainingItems,
  message,
  onDragEnd,
  getItemStyle,
  results
}: SentenceOrderingQuizContentProps) {
  return (
    <div
      className={`flex flex-col items-center w-full min-h-[300px] max-h-full overflow-x-hidden no-scrollbar ${
        isStartSolvingQuiz ? "justify-start" : "justify-center"
      }`}
    >
      <p className="font-noto-sans-kr text-2xl font-semibold text-white leading-[160%] text-center break-keep mt-0 mb-3 mx-0">
        {message} <br />
        {isSolved && correctCount !== null && (
          <>
            <span className="text-[36px]">{correctCount}</span>
            <span className="text-[22px]">/ {trainingItems?.length}</span>
          </>
        )}
      </p>

      {/* 드래그 & 드롭 UI 렌더링 */}
      {isStartSolvingQuiz && !isSolved && (
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="grid grid-cols-[50px_1fr] gap-4 w-full mt-0 mb-4 mx-0 max-sm:gap-0">
            <div className="grid items-center h-full">
              {trainingItems?.map((_, index) => (
                <div
                  className="flex items-center justify-center min-w-[50px] h-[50px] text-white text-2xl font-black max-xs:text-[20px]"
                  key={index}
                >
                  <p>{index + 1}</p>
                </div>
              ))}
            </div>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="grid items-center h-full"
                >
                  {trainingItems?.map((item, index) => (
                    <Draggable
                      key={item.index}
                      draggableId={String(item.index)}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          className="flex justify-between items-center w-full h-auto text-white text-[18px] font-semibold text-center break-keep mx-0 my-2 p-4 rounded-[10px] max-sm:min-h-[104px] gap-2"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <p className="w-full text-left leading-none">
                            {item.context}
                          </p>
                          <img
                            src={`${process.env.PUBLIC_URL}/images/icons/icon_drag.png`}
                            alt="Drag Handle Icon"
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      )}
      {isSolved && (
        <div className="grid grid-cols-[50px_1fr] gap-4 w-full mt-0 mb-4 mx-0 max-sm:gap-0 answer-check">
          <div className="grid items-center h-full">
            {trainingItems?.map((_, index) => (
              <div
                className="relative flex items-center justify-center min-w-[50px] h-[50px] text-white text-2xl font-black max-xs:text-[20px]"
                key={index}
              >
                <p>{index + 1}</p>
                <img
                  className="absolute top-[8px]"
                  src={`${process.env.PUBLIC_URL}/images/icons/icon_answer_${
                    results[index].isCorrect ? "correct_sm_blue" : "wrong_sm"
                  }.png`}
                  alt="Answer Check Icon"
                />
              </div>
            ))}
          </div>
          <div className="grid items-center h-full">
            {trainingItems?.map((item, index) => (
              <Fragment key={item.index}>
                <div
                  className={`flex justify-between items-center w-full h-auto text-white text-lg font-semibold text-center break-keep mx-0 my-2 p-4 rounded-[10px] max-sm:min-h-[104px] ${
                    results[index].isCorrect ? "bg-[#63b4ff]" : "bg-[#8696bb]"
                  }`}
                >
                  <p className="w-full text-left leading-none">
                    {item.context} <br className="hidden max-sm:block" />
                    <span>
                      {!results[index].isCorrect &&
                        `(정답: ${results[index].correctIndex}번째)`}
                    </span>
                  </p>
                </div>
              </Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
