import { useLocation } from "react-router-dom";

type AudioSettingsModalProps = {
  showSettings: boolean;
  speed: number;
  noiseLevel?: number;
  noiseTypes?: string[];
  noiseType?: string;
  noiseTypeImages?: { [key: string]: string };
  handleShowSettings: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleStopBubble: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleSpeedChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleNoiseLevelChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleNoiseTypeChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function AudioSettingsModal({
  showSettings,
  handleShowSettings,
  handleStopBubble,
  speed,
  handleSpeedChange,
  noiseLevel,
  handleNoiseLevelChange,
  noiseTypes,
  noiseType,
  handleNoiseTypeChange,
  noiseTypeImages
}: AudioSettingsModalProps) {
  // 소음하 검사 페이지인지 체크
  const location = useLocation();
  const isListenInNoisePage = location.pathname.includes("/listen_in_noise/");
  //

  return (
    <div
      className="absolute w-screen h-screen z-[999] top-0"
      onClick={handleShowSettings}
    >
      <div
        className={`absolute z-[1000] flex flex-col gap-6 w-full bg-[#333] pt-[60px] pb-[30px] px-[30px] rounded-[40px_40px_0_0] left-0 bottom-0 ${
          showSettings ? "animate-slideUp" : "animate-slideDown"
        }`}
        onClick={(e) => handleStopBubble(e)}
      >
        <button
          className="absolute right-[3%] top-[10%] max-sm:right-[10%]"
          onClick={handleShowSettings}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/icons/setting/icon_minimize.png`}
            alt="Minimize Settings Icon"
          />
        </button>
        <div className="flex gap-9 justify-center items-center w-full h-full font-bold text-xl text-white text-left max-sm:gap-0 max-sm:justify-start">
          <label
            className="flex gap-4 w-[10%] max-sm:flex-1 max-sm:flex-col max-sm:gap-0 max-sm:text-[16px]"
            htmlFor="speed-slider"
          >
            빠르기
          </label>
          <img
            className="mr-[6px]"
            src={`${process.env.PUBLIC_URL}/images/icons/setting/icon_speed_slow.png`}
            alt="Speed Slow Icon"
          />
          <input
            className="slider max-sm:flex-[2.5]"
            type="range"
            id="speed-slider"
            min="0"
            max="4"
            value={speed}
            onChange={handleSpeedChange}
          />
          <img
            className="ml-[12px]"
            src={`${process.env.PUBLIC_URL}/images/icons/setting/icon_speed_fast.png`}
            alt="Speed Fast Icon"
          />
        </div>

        {isListenInNoisePage && noiseTypes && noiseTypeImages && (
          <>
            <div className="flex gap-9 justify-center items-center w-full h-full font-bold text-xl text-white text-left max-sm:gap-0 max-sm:justify-start">
              <label
                className="flex gap-4 w-[10%] max-sm:flex-1 max-sm:flex-col max-sm:gap-0 max-sm:text-[16px]"
                htmlFor="noise-volume-slider"
              >
                소음
              </label>
              <img
                className="mr-[29px]"
                src={`${process.env.PUBLIC_URL}/images/icons/setting/icon_volume_low.png`}
                alt="Volume Low Icon"
              />
              <input
                className="slider max-sm:flex-[2.5]"
                type="range"
                id="noise-volume-slider"
                min="0"
                max="4"
                value={noiseLevel}
                onChange={handleNoiseLevelChange}
              />
              <img
                className="ml-[12px]"
                src={`${process.env.PUBLIC_URL}/images/icons/setting/icon_volume_high.png`}
                alt="Volume High Icon"
              />
            </div>
            <div className="flex gap-9 justify-center items-center w-full h-full font-bold text-xl text-white text-left mt-[24px] max-sm:gap-0 max-sm:justify-start max-sm:m-0">
              <label className="max-sm:flex max-sm:flex-1 max-sm:flex-col max-sm:text-base max-sm:gap-0">
                <p className="font-normal w-[80px] max-sm:w-auto">
                  {noiseType}
                </p>
              </label>
              <ul className="grid grid-rows-[1fr] grid-cols-[repeat(4,1fr)] justify-center gap-9 max-sm:flex-[2.5] max-sm:gap-3 max-xs:gap-[10px] max-xs:w-full">
                {noiseTypes.map((type) => (
                  <li key={type} className="flex items-center">
                    <input
                      className="hidden"
                      type="radio"
                      id={type}
                      name="noise-type"
                      value={type}
                      checked={noiseType === type}
                      onChange={handleNoiseTypeChange}
                    />
                    <label htmlFor={type}>
                      <span
                        className={`block w-[40px] h-[40px] bg-cover transition-transform duration-[0.3s] ease-[ease] rounded-[50%] max-xs:w-[35px] max-xs:h-[35px] ${
                          noiseType === type
                            ? "scale-[1.6] border-[#191919] border-2 border-solid max-sm:scale-[1.3]"
                            : ""
                        }`}
                        style={{
                          backgroundImage: `url(${noiseTypeImages[type]})`
                        }}
                      ></span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
