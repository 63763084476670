import { useEffect } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { supabase } from '@libs/supabaseClient';
import { CategoryMainState } from '@states/DataTypeState';
import { DailyTrainingState, ITrainingItem } from '@states/DailyTrainingState';
import { UserAuthState } from '@states/UserAuthState';
import { TrainingTypes } from '@constants/trainingType';
import { Tables } from '@libs/supabase.types';

type IDailyTrainingLogItemType = Tables<'daily_training_log'> & {
  daily_training_set: { index: number };
}

const useAppData = () => {
  const { user } = useRecoilValue(UserAuthState);
  const setCategoryMainState = useSetRecoilState(CategoryMainState);
  const setDailyTrainingState = useSetRecoilState(DailyTrainingState);
  
  useEffect(() => {
    supabase
      .from('category_main')
      .select('*')
      .order('index', { ascending: true })
    .then(({ data, error }) => {
      if (error) {
        throw error;
      }
      setCategoryMainState({ data });
    });
  }, []);

  const getDailyTrainingSetFromIndex = async (index: number) => {
    const { data, error } = await supabase
      .from('daily_training_set')
      .select('*')
      .eq('index', index)
      .limit(1)
      .single();

    if (error) {
      throw error;
    }
    
    return data;
  }

  const getDailyTrainingSetTotalCount = async () => {
    const { error, count } = await supabase
      .from('daily_training_set')
      .select('*', { count: 'exact', head: true });

    if (error) { 
      throw error;
    }
    
    return count;
  }

  const getDailyTrainingLogCount = async () => {
    const { error, count } = await supabase
      .from('daily_training_log')
      .select('*', { count: 'exact', head: true })
      .eq('user_id', user.id);

    if (error) { 
      throw error;
    }
    
    return count;
  }

  const getLastDailyTrainingLog = async () => {
    const { data, error } = await supabase
      .from('daily_training_log')
      .select(`*, daily_training_set(index)`)
      .eq('user_id', user.id)
      .order('created_at', { ascending: false })
      .limit(1)
      .maybeSingle();

    if (error) {
      throw error;
    } 

    return data as IDailyTrainingLogItemType;
  }

  const getDailyTrainingLogs = async (from: number, to: number) => {
    const { data, error } = await supabase
      .from('daily_training_log')
      .select(`*, daily_training_set(index)`)
      .eq('user_id', user.id)
      .order('created_at', { ascending: false })
      .range(from, to);

    if (error) {
      throw error;
    } 

    return data as IDailyTrainingLogItemType[];
  }

  const updateFirstDTState = async (isAddLog: boolean = false) => {
    const firstDTSetResult = await getDailyTrainingSetFromIndex(1);
    await setTodayDailyTrainingState(firstDTSetResult);
    if (isAddLog) {
      await addDailyTrainingLog(firstDTSetResult.id);
    }
    setDailyTrainingState((prev) => ({
      ...prev,
      dailyCount: 1,
      timestamp: new Date()
    }));
  }

  const getTodayTrainingLog = async () => {
    const lastDTLogResult = await getLastDailyTrainingLog();
    console.log("getTodayTrainingLog::lastDTLogResult", lastDTLogResult);

    if (lastDTLogResult === null) {
      // 로그 없음 
      // 첫 사용자 :: 로그 생성.
      updateFirstDTState(true);
      return;
    } 

    // 로그 있음
    const isNewDay = new Date().getDate() > new Date(lastDTLogResult.created_at).getDate();
    console.log("isNewDay", isNewDay);

    if (isNewDay) {
      // 날짜가 달라지면
      let lastIndex = lastDTLogResult.daily_training_set.index;
      let currentIndex = lastIndex + 1;
      const dtSetTotalCount = await getDailyTrainingSetTotalCount();
      console.log("dtSetTotalCount", dtSetTotalCount);

      if (dtSetTotalCount === lastIndex) {
        // 오늘의 학습 마지막인 경우, 다시 처음부터.
        currentIndex = 1;
      } 

      const beforeDTSet = await getDailyTrainingSetFromIndex(lastIndex);
      const currentDTSet = await getDailyTrainingSetFromIndex(currentIndex);
      await setTodayDailyTrainingState(currentDTSet, beforeDTSet);
      // 로그 추가
      await addDailyTrainingLog(currentDTSet.id);
      setDailyTrainingState((prev) => ({
        ...prev,
        dailyCount: prev.dailyCount + 1,
        timestamp: new Date(),
      }));
    } else {
      // 날짜 그대로
      const logCount = await getDailyTrainingLogCount();
      // console.log("getTodayTrainingLog::logCount", logCount);

      if (logCount === 1) {
        // 첫 사용자. 어제의 학습 신경 안써도 됨.
        updateFirstDTState();
      } else {
        const dtLogs = await getDailyTrainingLogs(0, 1);
        const beforeIndex = dtLogs[1].daily_training_set.index;
        const currentIndex = dtLogs[0].daily_training_set.index;
        const beforeDTSet = await getDailyTrainingSetFromIndex(beforeIndex);
        const currentDTSet = await getDailyTrainingSetFromIndex(currentIndex);
        await setTodayDailyTrainingState(currentDTSet, beforeDTSet);
        setDailyTrainingState((prev) => ({
          ...prev,
          timestamp: new Date()
        }));
      }
    }
  }

  const getDailyTrainingSet = async (index: number) => {
    const indexDTSet = await getDailyTrainingSetFromIndex(index);
    const todayTrainingGroup = await getTrainingGroup(indexDTSet);
    const indexTrainging = getDailyTraining(todayTrainingGroup);
    setDailyTrainingState((prev) => ({
      ...prev,
      index: indexTrainging,
      timestamp: new Date()
    }));
  }

  const updateTrainingSet = async () => {
    setDailyTrainingState((prev) => ({
      ...prev,
      today: prev.index,
      timestamp: new Date()
    }));
  }

  const getMaxTrainingSetIndex = async () => {
    return await supabase
      .from('daily_training_set')
      .select('index', { count: 'exact' })
      .order('index', { ascending: false })
      .limit(1)
      .single();
  }

  const addDailyTrainingLog = async (daily_training_set_id: string) => {
    const { data, error } = await supabase
      .from('daily_training_log')
      .insert([{ 
        daily_training_set_id: daily_training_set_id, 
        user_id: user.id
      }])
      .select()
      .single();

    if (error) {
      throw error;
    }
  
    return data;
  };

  const setTodayDailyTrainingState = async (
      currentDTSet: Tables<'daily_training_set'>, 
      beforeDTset?: Tables<'daily_training_set'>) => {
    // dailyTrainingState: today에 추가.
    const todayTrainingGroup = await getTrainingGroup(currentDTSet);
    const today = getDailyTraining(todayTrainingGroup);
    if (beforeDTset) {
      const yesterdayTrainingGroup = await getTrainingGroup(beforeDTset);
      const yesterday = getDailyTraining(yesterdayTrainingGroup);
      setDailyTrainingState((prev) => ({
        ...prev,
        today: today,
        yesterday: yesterday,
      }));
    } else {
      setDailyTrainingState((prev) => ({
        ...prev,
        today: today,
      }));
    }
  }

  const getTrainingGroup = async (dtSet: any) => {
    const { 
      training_set_list_part1, 
      training_set_list_part2, 
      training_set_list_part3, 
      training_set_list_part4 
    } = dtSet;
    const { data, error } = await supabase
      .from('training_group')
      .select('*')
      .or(
        `id.in.(${training_set_list_part1}), 
         id.in.(${training_set_list_part2}), 
         id.in.(${training_set_list_part3}), 
         id.in.(${training_set_list_part4})`
         .replace(/\s+/g, '')
      );
    
    if (error) {
      throw error;
    }

    return data;
  }

  const getDailyTraining = (data: Tables<'training_group'>[]) => {
    const today: ITrainingItem[] = TrainingTypes
      .filter(type => type.isCategory)
      .map((type) => ({
        type: type,
        items: [],
        progress: -1
      }));
    
    data.forEach((item: any) => {
      const typeIndex = TrainingTypes.findIndex(type => type.key === item.training_type);
      if (typeIndex !== -1) {
        today[typeIndex].items.push(item);
      }
    });

    return today;
  };

  const getCategorySubList = async (p_code: string) => {
    const { data, error } = await supabase
      .from('category_sub')
      .select('*')
      .eq('p_code', p_code)
      .order('index', { ascending: true });

    if (error) {
      throw error;
    }

    return data as Tables<"category_sub">[];
  }

  const getTrainingGroupList = async (category_sub_code: string) => {
    const { data, error } = await supabase
      .from('training_group')
      .select('*')
      .eq('category_sub_code', category_sub_code)
      .order('name', { ascending: true });
    if (error) {
      throw error;
    }
  
    return data as Tables<"training_group">[];
  }

  // storage RLS 설정 
  const getSignedSoundBucket = async (filePath: string) => {
    console.log('getSoundBucket', filePath);
    // TODO : set bucket name process.env 
    return await supabase
      .storage
      .from('se_training_resources')
      .createSignedUrl(filePath, 3600);
  }

  return { 
    getCategorySubList, 
    getTrainingGroupList, 
    getTodayTrainingLog, 
    getDailyTrainingSet, 
    updateTrainingSet, 
    getMaxTrainingSetIndex,
    getSignedSoundBucket
  };
};

export default useAppData;