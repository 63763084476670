import { atom } from "recoil";
import { ROUTES } from "@constants/common.routes";

// 오늘의 듣기 진행도
const localStorageEffect =
  (key: string) =>
  ({ setSelf, onSet }: any) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: any) => {
      localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

// 2024-06-04 경로 수정
export const tasksState = atom({
  key: "tasksState",
  default: [
    { name: "소음하 듣기", to: `${ROUTES.TODAY.LISTEN_IN_NOISE}/김치`, isComplete: false, isInProgress: false },
    { name: "짧은 이야기", to: `${ROUTES.TODAY.SHORT_STORY}/음식`, isComplete: false, isInProgress: false },
    { name: "긴 이야기", to: `${ROUTES.TODAY.LONG_STORY}/음식`, isComplete: false, isInProgress: false },
    { name: "문장 순서화", to: `${ROUTES.TODAY.ORDERING_SENTENCE}/김구`, isComplete: false, isInProgress: false },
  ],
  effects_UNSTABLE: [localStorageEffect("tasksState")],
});

// 카테고리별 진행도(김치, 김장, 음식 등...)
export type TaskCompletionState = {
  [key: string]: boolean;
};

export const taskCompletionState = atom({
  key: "taskCompletionState",
  default: {},
  effects_UNSTABLE: [localStorageEffect("taskCompletionState")],
});

// 각 주제의 진행 상황을 저장할 상태
export type TaskProgressState = {
  [key: string]: number;
};

export const taskProgressState = atom<TaskProgressState>({
  key: "taskProgressState",
  default: {},
  effects_UNSTABLE: [localStorageEffect("taskProgressState")],
});
