import { atom } from 'recoil';
import { recoilPersist } from "recoil-persist";
import { TrainingTypes} from '@constants/trainingType';
import { ITrainingType } from '@libs/common.types';
import { Tables } from '@libs/supabase.types';
const { persistAtom } = recoilPersist({
  key: 'todayTraining',
  storage: localStorage,
})

export interface ITrainingItem {
  type: ITrainingType;
  items: Tables<'training_group'>[];
}

interface IDailyTraining {
  today: ITrainingItem[];
  yesterday: ITrainingItem[];
  index: ITrainingItem[];
  dailyCount: number;
  timestamp?: Date;
}

const defaultDailyTraining = [
  {type : TrainingTypes[0], items: []},
  {type : TrainingTypes[1], items: []},
  {type : TrainingTypes[2], items: []},
  {type : TrainingTypes[3], items: []},
];

export const DailyTrainingDefault = {
  today: defaultDailyTraining,
  yesterday: defaultDailyTraining,
  index: defaultDailyTraining,
  dailyCount: 1,
  timestamp: new Date(),
}

export const DailyTrainingState = atom<IDailyTraining>({
  key: 'dailyTrainingState',
  default: DailyTrainingDefault,
  effects_UNSTABLE: [persistAtom],
});