type QuizNavigationProps = {
  isCompleted: boolean;
  blinkNextButton: boolean;
  trainingItemProgress: number;
  handlePreviousQuestion: () => void;
  handleNextQuestion: () => void;
  progressCount: number;
  progressTotalCount: number;
  isStartSolvingQuiz?: boolean;
};

export default function QuizNavigation({
  trainingItemProgress,
  isCompleted,
  blinkNextButton,
  handlePreviousQuestion,
  handleNextQuestion,
  progressCount,
  progressTotalCount,
  isStartSolvingQuiz
}: QuizNavigationProps) {
  if (isStartSolvingQuiz) {
    return <div className="flex justify-between items-center"></div>;
  }

  return (
    <div className="flex justify-between items-center">
      <div className="flex gap-4">
        <button
          className={`flex justify-center items-center border px-4 py-3 rounded-[100%] border-solid border-white ${
            trainingItemProgress === 1
              ? "opacity-50 cursor-not-allowed"
              : "opacity-100 cursor-pointer"
          }`}
          onClick={handlePreviousQuestion}
          disabled={trainingItemProgress === 1}
        >
          <img
            className={`${
              trainingItemProgress === 1 ? "opacity-50" : "opacity-100"
            }`}
            src={`${process.env.PUBLIC_URL}/images/icons/icon_arrow_left.png`}
            alt="Prev Question Icon"
          />
        </button>
        {!isCompleted && (
          <button
            className={`flex justify-center items-center border px-4 py-3 rounded-[100%] border-solid border-white ${
              blinkNextButton ? "animate-blink" : ""
            }`}
            onClick={handleNextQuestion}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/icon_arrow_right.png`}
              alt="Next Question Icon"
            />
          </button>
        )}
      </div>
      <p className="font-pretendard-variable text-[22px] text-white tracking-[-1px]">
        <span className="text-[36px] font-bold">
          {progressCount}
          &nbsp;/&nbsp;
          {progressTotalCount}
        </span>
      </p>
    </div>
  );
}
