import { Link, useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { useRecoilState, useRecoilValue } from "recoil";

import { UserAuthState } from "@states/UserAuthState";
import Utils from "@libs/utils";

import type { ProfileDataType } from "@libs/common.types";

import useAuth from "@hooks/useAuth";

export default function MyPage() {
  const [authSession, setAuthSession] = useRecoilState(UserAuthState);
  const { user, profile } = useRecoilValue(UserAuthState);
  const auth = useAuth();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<ProfileDataType>();

  const onSubmit: SubmitHandler<ProfileDataType> = async (data) => {
    console.log("onSubmit", data);
    const profile = await auth.updateProfile(user.id, data);
    setAuthSession({
      ...authSession,
      profile: profile
    });
    alert("사용자 정보가 성공적으로 수정되었습니다.");
  };

  const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Utils.changeDate(event.target.value);
    setValue("birthday", newValue);
  };

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Utils.changePhone(event.target.value);
    setValue("phone", newValue);
  };

  const logout = async () => {
    const result = await auth.signOut();
    alert("로그아웃 되었습니다.");
    navigate("/");
  };

  return (
    <>
      <div className="bg-[#fff] w-full h-[calc(100vh_-_75px)] pt-[30px] pb-0 px-0 max-sm:h-screen max-sm:overflow-x-hidden max-sm:p-0 max-sm:bg-[#f2f2f7]">
        <div className="flex flex-col justify-center items-center max-w-[1440px] h-auto mx-auto my-0 max-sm:overflow-y-scroll max-sm:w-full max-sm:h-auto max-sm:p-[30px]">
          <div className="flex flex-col items-center w-[1124px] h-[717px] bg-white relative p-[50px] rounded-[40px] max-sm:bg-[initial] max-sm:h-auto max-sm:w-full max-sm:p-0">
            <div className="hidden max-sm:flex max-sm:justify-between max-sm:items-center max-sm:w-full max-sm:text-xl max-sm:font-semibold max-sm:mt-0 max-sm:mb-[33px] max-sm:mx-0">
              <Link className="flex items-center" to="/main">
                <img
                  className="ml-0 mr-5 my-0"
                  src={`${process.env.PUBLIC_URL}/images/icons/icon_back.png`}
                  alt="Back Icon"
                />
                <span>회원정보</span>
              </Link>
              <button
                className="flex justify-center items-center min-w-[80px] text-xl font-semibold break-keep px-8 py-3 rounded-[100px] bg-[#d3d3d3] text-[#3a3a3a]"
                onClick={logout}
              >
                로그아웃
              </button>
            </div>
            <div className="items-center flex justify-center relative w-full mb-5 max-sm:hidden">
              <span className="max-sm:hidden text-2xl font-bold">회원정보</span>
              <button
                className="absolute right-0 flex justify-center items-center min-w-[80px] text-xl font-semibold break-keep px-8 py-3 rounded-[100px] bg-[#d3d3d3] text-[#3a3a3a]"
                onClick={logout}
              >
                로그아웃
              </button>
            </div>
            <form
              id="updateProfileForm"
              className="w-full"
              onSubmit={handleSubmit(onSubmit)}
            >
              <ul className="flex flex-col justify-center items-start gap-4 w-full">
                <li className="flex justify-center items-baseline w-full mt-0 mb-4 mx-0">
                  <p className="flex justify-center items-center shrink-0 w-[25px] h-[25px] bg-[#40a0ff] text-white text-[13px] font-normal ml-0 mr-4 my-0 rounded-[100%] max-xs:mr-2">
                    01
                  </p>
                  <p className="w-[10%] text-base font-medium ml-0 mr-4 my-0 max-sm:w-full max-xs:mr-2">
                    아이디
                  </p>
                  <div className="flex justify-center items-center flex-col w-[30%] max-sm:w-full">
                    <div className="content-center w-[200px] h-[46px] text-[#777] text-base font-normal pl-3 border-[none] max-sm:bg-white max-sm:p-3 max-sm:rounded-[10px] max-sm:overflow-x-scroll max-sm:overflow-y-hidden max-xs:w-[146px]">
                      {user.email}
                    </div>
                  </div>
                </li>
                <li className="flex justify-center items-baseline w-full">
                  <p className="flex justify-center items-center shrink-0 w-[25px] h-[25px] bg-[#40a0ff] text-white text-[13px] font-normal ml-0 mr-4 my-0 rounded-[100%] max-xs:mr-2">
                    02
                  </p>
                  <p className="w-[10%] text-base font-medium ml-0 mr-4 my-0 max-sm:w-full max-xs:mr-2">
                    사용자명
                  </p>
                  <div className="flex justify-center items-center flex-col w-[30%] max-sm:w-full">
                    <input
                      type="text"
                      defaultValue={profile.name}
                      id="name"
                      className="content-center w-[200px] h-[46px] text-[#777] text-base font-normal pl-3 border-[none] border-b-[#8696bb] border-b border-solid max-sm:bg-white max-sm:p-3 max-sm:rounded-[10px] max-sm:border-none max-xs:w-[146px]"
                      {...register("name", {
                        required: "사용자명은 필수 입력 항목입니다.",
                        minLength: {
                          value: 2,
                          message: "사용자명은 2자 이상이여야 합니다."
                        },
                        maxLength: {
                          value: 12,
                          message: "사용자명은 12자 이하이여야 합니다."
                        }
                      })}
                    />
                    <div className="text-[#ff0000] text-[14px] font-normal mt-[5px] w-full text-center">
                      <span>{errors.name && errors.name.message}&nbsp;</span>
                    </div>
                  </div>
                </li>
                <li className="flex justify-center items-baseline w-full">
                  <p className="flex justify-center items-center shrink-0 w-[25px] h-[25px] bg-[#40a0ff] text-white text-[13px] font-normal ml-0 mr-4 my-0 rounded-[100%] max-xs:mr-2">
                    03
                  </p>
                  <p className="w-[10%] text-base font-medium ml-0 mr-4 my-0 max-sm:w-full max-xs:mr-2">
                    전화번호
                  </p>
                  <div className="flex justify-center items-center flex-col w-[30%] max-sm:w-full">
                    <input
                      type="text"
                      defaultValue={profile.phone}
                      className="content-center w-[200px] h-[46px] text-[#777] text-base font-normal pl-3 border-b-[#8696bb] border-[none] border-b border-solid max-sm:bg-white max-sm:p-3 max-sm:rounded-[10px] max-sm:border-none max-xs:w-[146px]"
                      id="phone"
                      maxLength={13}
                      minLength={13}
                      {...register("phone", {
                        required: "전화번호 항목을 입력해주세요.",
                        pattern: {
                          value:
                            /^(010-[1-9]{1}\d{3}-[1-9]{1}\d{3})|((02|0[3-9]{1}[0-9]{1})-[1-9]{1}\d{2,3}-[1-9]{1}\d{3})$/,
                          message: "올바른 전화번호 형식이 아닙니다."
                        }
                      })}
                      onChange={handleChangePhone}
                    />
                    <div className="text-[#ff0000] text-[14px] font-normal mt-[5px] w-full text-center">
                      <span>{errors.phone && errors.phone.message}&nbsp;</span>
                    </div>
                  </div>
                </li>
                <li className="flex justify-center items-baseline w-full">
                  <p className="flex justify-center items-center shrink-0 w-[25px] h-[25px] bg-[#40a0ff] text-white text-[13px] font-normal ml-0 mr-4 my-0 rounded-[100%] max-xs:mr-2">
                    04
                  </p>
                  <p className="w-[10%] text-base font-medium ml-0 mr-4 my-0 max-sm:w-full max-xs:mr-2">
                    생일
                  </p>
                  <div className="flex justify-center items-center flex-col w-[30%] max-sm:w-full">
                    <input
                      type="text"
                      defaultValue={profile.birthday}
                      className="content-center w-[200px] h-[46px] text-[#777] text-base font-normal pl-3 border-[none] border-b-[#8696bb] border-b border-solid max-sm:bg-white max-sm:p-3 max-sm:rounded-[10px] max-sm:border-none max-xs:w-[146px]"
                      id="birthday"
                      maxLength={10}
                      minLength={10}
                      {...register("birthday", {
                        required: "생년월일 항목을 입력해주세요.",
                        pattern: {
                          value: /^\d{4}-\d{2}-\d{2}$/,
                          message:
                            "올바른 날짜(1980-01-01) 형식으로 입력해 주세요."
                        },
                        validate: (fieldValue: string | undefined) =>
                          Utils.validateDate(fieldValue) ||
                          "올바른 날짜 입력 범위가 아닙니다."
                      })}
                      onChange={handleChangeDate}
                    />
                    <div className="text-[#ff0000] text-[14px] font-normal mt-[5px] w-full text-center">
                      <span>
                        {errors.birthday && errors.birthday.message}&nbsp;
                      </span>
                    </div>
                  </div>
                </li>
                <li className="flex justify-center items-baseline w-full">
                  <p className="flex justify-center items-center shrink-0 w-[25px] h-[25px] bg-[#40a0ff] text-white text-[13px] font-normal ml-0 mr-4 my-0 rounded-[100%] max-xs:mr-2">
                    05
                  </p>
                  <p className="w-[10%] text-base font-medium ml-0 mr-4 my-0 max-sm:w-full max-xs:mr-2">
                    성별
                  </p>
                  <div className="flex justify-center items-center flex-col w-[30%] max-sm:w-full">
                    <select
                      id="gender"
                      defaultValue={profile.gender}
                      className="content-center w-[200px] h-[46px] text-[#777] text-base font-normal pl-3 border-[none] max-sm:bg-white max-sm:p-3 max-sm:rounded-[10px] max-xs:w-[146px]"
                      {...register("gender")}
                    >
                      <option value="M">남성</option>
                      <option value="F">여성</option>
                    </select>
                  </div>
                </li>
              </ul>
              <div className="flex justify-evenly mt-[50px] mb-0 mx-0 max-sm:mt-[30px]">
                <button
                  className="flex justify-center items-center w-[66px] h-[66px] bg-[#40a0ff] mx-[15px] my-0 rounded-[100%]"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.reload();
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icons/icon_refresh.png`}
                    alt="Refresh Icon"
                  />
                </button>
                {!isSubmitting ? (
                  <button
                    className="flex justify-center items-center w-[66px] h-[66px] bg-[#40a0ff] mx-[15px] my-0 rounded-[100%]"
                    type="submit"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/icons/icon_check.png`}
                      alt="Check Icon"
                    />
                  </button>
                ) : (
                  <button disabled>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/icons/icon_check.png`}
                      alt="Check Icon"
                    />
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
