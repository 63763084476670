import { useNavigate } from "react-router-dom";
import { ROUTES } from "@constants/common.routes";

type UseNavigateToBackProps = {
  setAudioUrls?: React.Dispatch<React.SetStateAction<any[]>>;
  setContextAudioUrls?: React.Dispatch<React.SetStateAction<any[]>>;
  setQuestionAudioUrls?: React.Dispatch<React.SetStateAction<any[]>>;
  setAudio: React.Dispatch<React.SetStateAction<HTMLAudioElement | null>>;
  state: any;
  day?: string;
};

export default function useNavigateToBack({
  setAudioUrls,
  setContextAudioUrls,
  setQuestionAudioUrls,
  setAudio,
  state,
  day
}: UseNavigateToBackProps) {
  const navigate = useNavigate();
  const { main, sub, page } = state.categoryData;
  const { type } = state;

  const navigateToBack = () => {
    if (setAudioUrls) setAudioUrls([]); // 오디오 URL 초기화
    if (setContextAudioUrls) setContextAudioUrls([]); // 문장 오디오 URL 초기화
    if (setQuestionAudioUrls) setQuestionAudioUrls([]); // 문제 오디오 URL 초기화
    setAudio(null); // 오디오 초기화

    if (day) {
      if (day === "today") {
        navigate(`${ROUTES.TODAY.MAIN}`);
      } else if (day === "yesterday") {
        navigate(`${ROUTES.YESTERDAY.MAIN}`);
      } else {
        navigate(`/main`);
      }
    } else if (main !== undefined || sub !== undefined) {
      navigate(`/main/category/${main}/${sub}`, {
        state: state.categoryData.data
      });
    } else {
      navigate(`/main/training/listening_by_training/${type.toLowerCase()}`, {
        state: { ...state, page }
      });
    }
  };

  return navigateToBack;
}
