import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";

import dayjs from "dayjs";
import anime from "animejs";

import MainFooter from "@components/common/MainFooter";

import useAuth from "@hooks/useAuth";

import { UserAuthState } from "@states/UserAuthState";

export default function Main() {
  const { user, profile, isAuthenticated } = useRecoilValue(UserAuthState);
  const [lastLogin, setLastLogin] = useState<string>("");
  const auth = useAuth();

  useEffect(() => {
    setTimeout(() => {
      const x = window.innerWidth / 2;
      const y = window.innerHeight / 2;
      transitionPage(0, 1, x, y);
    }, 700);
  }, []);

  useEffect(() => {
    if (user) {
      setLastLogin(dayjs(user.last_sign_in_at).format("YYYY-MM-DD HH:mm:ss"));
    }
  }, [user]);

  const transitionPage = (
    curr: number,
    next: number,
    x: number,
    y: number
  ): void => {
    const pages = document.querySelectorAll(
      ".login_page"
    ) as NodeListOf<HTMLElement>;

    pages[next].style.zIndex = (
      parseInt(getComputedStyle(pages[curr]).zIndex, 10) + 1
    ).toString();

    anime({
      targets: pages[next],
      update: (anim: any) => {
        pages[next].style.clipPath = `circle(${
          anim.progress * 2
        }% at ${x}px ${y}px)`;
      }
    });
  };

  const handleSignout = async () => {
    const result = await auth.signOut();
    if (result) {
      alert("로그아웃 되었습니다.");
      // window.location.reload();
    }
  };

  return (
    <>
      <div className="font-pretendard-variable leading-none overflow-hidden">
        <div className="login_page bg-[#40a0ff] flex-col z-[2] absolute overflow-hidden w-screen h-screen flex items-center justify-center top-0">
          <div className="flex justify-center w-screen overflow-hidden">
            <img
              // onClick="nextPage(0, 1);"
              src={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
              alt="SpeechEar_Logo"
            />
          </div>
        </div>
        <div className="login_page bg-login-gradient justify-end absolute overflow-hidden w-screen h-screen flex items-center top-0 max-sm:relative max-sm:flex max-sm:flex-col max-sm:justify-start max-sm:items-center">
          <div className="absolute top-0 left-0 -z-[1]">
            <img
              className="block w-full max-sm:hidden"
              src={`${process.env.PUBLIC_URL}/images/logo/circle_logo.png`}
              alt="CircleLogo"
            />
            <img
              className="hidden max-sm:block max-sm:w-full"
              src={`${process.env.PUBLIC_URL}/images/logo/circle_logo_center.png`}
              alt="CircleLogo_Mob"
            />
          </div>
          <div className="h-screen flex items-center flex-col justify-center mr-[162px] max-sm:mx-auto max-sm:my-0">
            {isAuthenticated ? (
              <>
                <div className="absolute text-white text-[0.8em] font-bold leading-4 text-center w-screen top-4">
                  <p>:: 로그인정보 ::</p>
                  <p>
                    {profile && profile.name}({user.email}) 님 접속중입니다.
                  </p>
                  <p>마지막 접속: {lastLogin}</p>
                </div>
                <div className="w-full">
                  <div className="text-center mt-16 max-sm:my-[20px]">
                    <Link
                      to="/login"
                      className="bg-white inline-block box-border text-[#545454] cursor-pointer opacity-100 select-none touch-manipulation w-[222px] text-[1.6em] font-semibold px-[18px] py-4 rounded-[38px] border-0 max-sm:w-[195px] max-sm:h-[50px] max-sm:text-[21px] max-sm:content-center max-sm:p-2.5"
                    >
                      <div>말귀 훈련 시작</div>
                    </Link>
                  </div>
                  <div className="text-center mt-16 max-sm:my-[20px]">
                    <button
                      type="button"
                      className="bg-white inline-block box-border text-[#545454] cursor-pointer opacity-100 select-none touch-manipulation w-[222px] text-[1.6em] font-semibold px-[18px] py-4 rounded-[38px] border-0 max-sm:w-[195px] max-sm:h-[50px] max-sm:text-[21px] max-sm:content-center max-sm:p-2.5"
                      onClick={handleSignout}
                    >
                      로그아웃
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div className="w-full">
                <div className="text-center mt-16 max-sm:my-[20px] max-sm:mt-[20vh]">
                  <Link
                    to="/login"
                    className="bg-white inline-block box-border text-[#545454] cursor-pointer opacity-100 select-none touch-manipulation w-[222px] text-[1.6em] font-semibold px-[18px] py-4 rounded-[38px] border-0 max-sm:w-[195px] max-sm:h-[50px] max-sm:text-[21px] max-sm:content-center max-sm:p-2.5"
                  >
                    로그인
                  </Link>
                </div>
                <div className="text-center mt-16 max-sm:my-[20px]">
                  <Link
                    to="/signup"
                    className="bg-white inline-block box-border text-[#545454] cursor-pointer opacity-100 select-none touch-manipulation w-[222px] text-[1.6em] font-semibold px-[18px] py-4 rounded-[38px] border-0 max-sm:w-[195px] max-sm:h-[50px] max-sm:text-[21px] max-sm:content-center max-sm:p-2.5"
                  >
                    회원가입
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
        <MainFooter />
      </div>
    </>
  );
}
