import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import ViewSentencePopup from "@components/mobile/ViewSentencePopup";
import TestPageHeader from "@components/tests/TestPageHeader";
import QuizTopicHeader from "@components/tests/QuizTopicHeader";
import ShortStoriesListeningQuizContent from "@components/tests/quizContents/ShortStoriesListeningQuizContent";
import QuizNavigation from "@components/tests/QuizNavigation";
import ListeningControlSection from "@components/tests/ListeningControlSection";
import AudioSettingsModal from "@components/tests/AudioSettingsModal";

// import { taskCompletionState, tasksState } from "@states/progress";
import { showOptionsState } from "@states/settings";

import { ROUTES } from "@constants/common.routes";
import { TrainingType } from "@constants/trainingType";

import useAppData from "@hooks/useAppData";
import useWaveAnimation from "@hooks/useWaveAnimation";
import useBackgroundStyle from "@hooks/useBackgroundStyle";
import usePlaybackSpeed from "@hooks/usePlaybackSpeed";
import useAudioSettings from "@hooks/useAudioSettings";

import Utils from "@libs/utils";
import { ITrainingResponseRecord } from "@libs/common.types";
import type {
  AudioURLType,
  TrainingResponseCountType,
  TrainingItemType,
  TrainingIsCorrectType
} from "@libs/common.types";
import useNavigateToNext from "@hooks/useNavigateToNext";
import useNavigateToBack from "@hooks/useNavigateToBack";

export default function ShortStoriesListening() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { itemName, day } = useParams<{ itemName: string; day: string }>();
  const { getSignedSoundBucket } = useAppData();

  const showOptions = useRecoilValue(showOptionsState); // 보기 표시 여부 Recoil 상태 값 읽기

  const defaultMessage = "문제를 듣고 정답을 골라주세요.";

  const [trainingGroupId, setTrainingGroupId] = useState<string | null>();
  const [trainingItems, setTrainingItems] = useState<TrainingItemType[]>();
  const [trainingItemProgress, setTrainingItemProgress] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);

  const [blinkNextButton, setBlinkNextButton] = useState(false); // 응답 시 다음 문제 버튼 애니메이션을 관리하는 상태

  const [isViewSentence, setIsViewSentence] = useState(false); // 문장 보기(팝업) 상태

  // 2024-06-15 오디오 관리 로직 구현
  const [contextAudioUrls, setContextAudioUrls] = useState<AudioURLType[]>([]);
  const [questionAudioUrls, setQuestionAudioUrls] = useState<AudioURLType[]>(
    []
  );

  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [questionAudio, setQuestionAudio] = useState<HTMLAudioElement | null>(
    null
  );

  const [isPlay, setIsPlay] = useState(false);
  const [isQuestionPlay, setIsQuestionPlay] = useState(false);

  // const [isQuestionPlay, setIsQuestionPlay] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1.0); // 기본 재생 속도

  const [message, setMessage] = useState(defaultMessage);
  const [isSolvingQuiz, setIsSolvingQuiz] = useState(false);
  const [isClickAnswer, setIsClickAnswer] = useState(false); // 보기 버튼 클릭 여부
  const [selectedAnswer, setSelectedAnswer] = useState<string[]>([]); // 선택된 응답
  const [correctAnswer, setCorrectAnswer] = useState<string[]>([]); // 정답
  const [answerList, setAnswerList] = useState<string[]>([]); // 보기 리스트

  const [responses, setResponses] = useState<ITrainingResponseRecord>({});
  const [currentResponse, setCurrentResponse] =
    useState<TrainingIsCorrectType | null>(null);
  const [responseCounts, setResponseCounts] =
    useState<TrainingResponseCountType>({
      correct: 0,
      incorrect: 0
    });

  // 재생 중일 때 듣기 버튼 애니메이션 적용 훅
  const currentWave = useWaveAnimation(isPlay);

  // 배경 이미지 적용 훅
  const { p_code, code } = state.categoryData;
  const backgroundStyle = useBackgroundStyle(p_code, code, isCompleted);

  // 재생 속도 조절 훅
  const { speed, handleSpeedChange } = usePlaybackSpeed(audio);

  // 듣기 설정 확장 훅
  const { showSettings, shouldRender, handleShowSettings, handleStopBubble } =
    useAudioSettings();

  // const [taskCompletion, setTaskCompletion] =
  //   useRecoilState(taskCompletionState);

  // console.log("trainingGroupId", trainingGroupId);
  // console.log("trainingItems", trainingItems);
  // console.log("trainingItemProgress", trainingItemProgress);

  useEffect(() => {
    // escaping direct url loading -> temporary solution
    // TODO: show error massage and redirect to main page
    // TODO: or reload data gracefully
    if (!state) navigate("/main");
  }, [state]);

  // useEffect(() => {
  //   console.log("audioUrls", audioUrls);
  // }, [audioUrls]);

  // 문제 데이터 불러오기
  useEffect(() => {
    if (!state) return;
    const currentItemIndex = state.currentIndex;
    const currentItem = state.items[currentItemIndex];
    const item = Utils.findTrainingItemsById(state.type, currentItem.id);

    if (item) {
      if (!(item.items && item.items.length > 0)) {
        alert("문제 데이터를 준비중입니다.");
        navigateToBack();
        return;
      }
      setTrainingItems(item.items);
      // TrainingItemProgress
      // 0: 준비 상태, 1: 문제풀이 시작번호, trainingItems.length : 문제풀이 완료
      setTrainingItemProgress(1);
      setTrainingGroupId(item.id);
    }
  }, [state && state.currentIndex]);
  //

  useEffect(() => {
    if (trainingItems && trainingItems.length > 0) {
      const currentItem = trainingItems[trainingItemProgress - 1];

      if (currentItem) {
        if (
          currentItem.answer &&
          currentItem.wrongAnswer1 &&
          currentItem.wrongAnswer2
        ) {
          const correctAnswerList = [currentItem.answer];
          const wrongAnswerList = [
            currentItem?.wrongAnswer1,
            currentItem?.wrongAnswer2
          ];
          // random merge correct and wrong answers
          const mergedAnswers = Utils.arrayMergeAndShuffle(
            correctAnswerList,
            wrongAnswerList
          );

          if (currentItem.questionContext) {
            // setMessage(currentItem.questionContext);
          }

          setAnswerList(mergedAnswers);
          setCorrectAnswer(correctAnswerList);
        }
      }

      setIsCompleted(trainingItemProgress > trainingItems.length);
      setCurrentResponse(null);
    }
  }, [trainingItemProgress, trainingItems]);

  useEffect(() => {
    if (!state) return;
    if (!(trainingItems && trainingItems.length > 0)) return;

    async function getBucket(filePath: string) {
      if (filePath === "") return;
      const result = await getSignedSoundBucket(filePath);
      console.log(`Fetched URL for ${filePath}:`, result.data?.signedUrl);
      return result;
    }

    const contextPromises = trainingItems.map(async (item) => {
      const path = Utils.generateAudioBucketPath(state.type, item.contextCode);
      console.log("Context path:", path); // 로그 추가
      if (path) {
        const result = await getBucket(path);
        if (result && result.data) {
          console.log(`Fetched URL for ${path}:`, result.data.signedUrl);
          return { index: item.index, path: path, url: result.data.signedUrl };
        }
      }
    });

    const questionPromises = trainingItems.map(async (item) => {
      const path = Utils.generateAudioBucketPath(state.type, item.questionCode);
      console.log("Question path:", path); // 로그 추가
      if (path) {
        const result = await getBucket(path);
        if (result && result.data) {
          console.log(`Fetched URL for ${path}:`, result.data.signedUrl);
          return { index: item.index, path: path, url: result.data.signedUrl };
        }
      }
    });

    Promise.all(contextPromises)
      .then((results) => {
        const newValues = results.filter(
          (url) => url !== undefined
        ) as AudioURLType[];
        console.log("Context audio URLs:", newValues);
        setContextAudioUrls(newValues);
        return newValues;
      })
      .then((newValues) => {
        if (newValues.length > 0) {
          const audioUrl = newValues[0].url;
          console.log("Setting initial context audio URL:", audioUrl);
          setAudio(new Audio(audioUrl));
        }
      });

    Promise.all(questionPromises)
      .then((results) => {
        const newValues = results.filter(
          (url) => url !== undefined
        ) as AudioURLType[];
        console.log("Question audio URLs:", newValues);
        setQuestionAudioUrls(newValues);
        return newValues;
      })
      .then((newValues) => {
        if (newValues.length > 0) {
          const quizAudioUrl = newValues[0].url;
          console.log("Setting initial question audio URL:", quizAudioUrl);
          setQuestionAudio(new Audio(quizAudioUrl));
        }
      });
  }, [trainingGroupId]);

  useEffect(() => {
    if (!trainingItems) return;
    if (trainingItems.length === 0) return;
    if (contextAudioUrls.length === 0) return;
    if (contextAudioUrls.length !== trainingItems.length) return;

    if (audio) audio.pause();
    if (questionAudio) questionAudio.pause();

    const currentItem = trainingItems[trainingItemProgress - 1];

    if (currentItem) {
      const audioPath = contextAudioUrls.find(
        (audio) => audio.index === currentItem.index
      );
      const qAudioPath = questionAudioUrls.find(
        (audio) => audio.index === currentItem.index
      );

      if (audioPath) {
        const newAudio = new Audio(audioPath.url);
        newAudio.playbackRate = playbackRate;
        setAudio(newAudio);
      }

      if (qAudioPath) {
        const newQAudio = new Audio(qAudioPath.url);
        // newQAudio.playbackRate = playbackRate;
        setQuestionAudio(newQAudio);
      }
    } else {
      setAudio(null);
      setQuestionAudio(null);
    }

    setSelectedAnswer([]); // 선택된 응답을 초기화
    setIsClickAnswer(false); // 클릭 상태 초기화
    setMessage("문제를 듣고 정답을 골라주세요."); // 메시지 초기화
    setIsSolvingQuiz(false); // 퀴즈 풀기 상태 초기화
  }, [trainingItemProgress]);

  useEffect(() => {
    console.log("isQuestionPlay", isQuestionPlay);
  }, [isQuestionPlay]);

  const isPlaying = (audioElem: HTMLAudioElement) => {
    return audioElem.duration > 0 && !audioElem.paused;
  };

  const initAudioPlay = () => {
    if (audio) audio.currentTime = 0; // 오디오 초기화
    if (questionAudio) questionAudio.currentTime = 0; // 오디오 초기화
    setIsPlay(false);
    setIsQuestionPlay(false);
  };

  const replayQuiz = () => {
    setIsPlay(false);
    setIsQuestionPlay(!isQuestionPlay);
  };

  // 재생 속도 조절 로직 추가
  useEffect(() => {
    if (audio) {
      audio.playbackRate = playbackRate;
    }

    if (questionAudio) {
      // questionAudio.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  useEffect(() => {
    if (!audio) return;

    if (isPlay) {
      audio.play();
    } else {
      audio.pause();
    }

    audio.onended = () => {
      setIsPlay(false);
    };

    return () => {
      audio.pause();
    };
  }, [audio, isPlay]);

  useEffect(() => {
    if (!questionAudio) return;

    if (isQuestionPlay) {
      questionAudio.play();
    } else {
      questionAudio.pause();
    }

    questionAudio.onended = () => {
      setIsQuestionPlay(false);
    };

    return () => {
      questionAudio.pause();
    };
  }, [questionAudio, isQuestionPlay]);

  useEffect(() => {
    if (trainingItemProgress) {
      if (selectedAnswer.length > 0) {
        handleAnswer(selectedAnswer, trainingItemProgress);
      }
    }
  }, [selectedAnswer]);

  // 정답 관리 및 사용자 응답 추적 로직 (정답 개수)
  const handleAnswer = (answer: string[], progress: number) => {
    // if (responses[progress]) return;
    if (!trainingItems) return;
    let isCorrect = false;
    const currentItem = trainingItems[trainingItemProgress - 1];
    if (currentItem) {
      isCorrect = currentItem.answer === answer[0];
    }

    setIsClickAnswer(true);

    const msg = isCorrect ? "정답입니다!" : "아쉽네요!";
    const ans = answer[0];
    const resp = isCorrect ? "correct" : "incorrect";

    setMessage(msg);
    setResponses((prev) => {
      const updatedResponses = {
        ...prev,
        [progress]: { answer: ans, response: resp }
      } as ITrainingResponseRecord;

      let correctCount = 0;
      let incorrectCount = 0;
      for (const [key, value] of Object.entries(updatedResponses)) {
        // console.log(`${key}: ${value}`);
        if (value && value.response) {
          if (value.response === "correct") {
            correctCount++;
          } else if (value.response === "incorrect") {
            incorrectCount++;
          }
        }
      }
      setResponseCounts({ correct: correctCount, incorrect: incorrectCount });

      return updatedResponses;
    });

    setCurrentResponse(resp);

    // 응답 후 "다음 문제" 버튼에 강조 효과 추가
    setBlinkNextButton(true);
  };

  // 문장 듣기 버튼을 클릭했을 때의 로직
  const handlePlay = () => {
    if (!audio) return;
    // setIsSolvingQuiz(false);
    setIsPlay(!isPlay);
    setIsQuestionPlay(false);
    if (questionAudio) questionAudio.currentTime = 0;
  };

  // 퀴즈 풀기 버튼을 클릭했을 때의 로직
  const handleSolvingQuiz = () => {
    if (!questionAudio) return;
    setIsSolvingQuiz(true);
    setIsPlay(false);
    setIsQuestionPlay(!isQuestionPlay);
    if (audio) audio.currentTime = 0;
  };

  const initTrainingProgressData = () => {
    console.log("initTrainingProgressData");
    setIsSolvingQuiz(false);
    setIsClickAnswer(false);
    setMessage(defaultMessage);
    setIsViewSentence(false);
    setSelectedAnswer([]);
  };

  const deleteRespState = (key: number) => {
    setResponses((prevState) => {
      const { [key]: _, ...newState } = prevState;
      return newState;
    });
  };

  const handlePreviousQuestion = () => {
    initAudioPlay();
    if (isCompleted) {
      setIsCompleted(false);
    }
    initTrainingProgressData();
    // 문제 뒤로가기 시, 현재, 이전 문제의 응답을 제거
    deleteRespState(trainingItemProgress);
    deleteRespState(trainingItemProgress - 1);

    setTrainingItemProgress(trainingItemProgress - 1);
  };

  const handleNextQuestion = () => {
    if (!responses[trainingItemProgress]) {
      alert("응답을 선택해 주세요!");
      return;
    }
    initAudioPlay();

    initTrainingProgressData();
    setTrainingItemProgress(trainingItemProgress + 1);

    // "다음 문제" 버튼 강조 효과 제거
    setBlinkNextButton(false);
  };

  // 문장 보기 버튼을 클릭했을 때의 로직 (팝업)
  const handleViewSentence = () => {
    if (audio) audio.pause();
    if (questionAudio) questionAudio.pause();

    setIsViewSentence(true);
  };

  // "한번 더 듣기" 버튼 클릭 시 상태 초기화
  const handleReset = () => {
    initAudioPlay();
    initTrainingProgressData();
    setIsCompleted(false); // 테스트 완료 상태를 초기화
    setTrainingItemProgress(1); // 테스트 진행 상황 초기화
    setResponses({}); // 사용자의 응답 저장 객체를 초기화
  };
  //

  // 뒤로가기 훅
  const navigateToBack = useNavigateToBack({
    setContextAudioUrls,
    setQuestionAudioUrls,
    setAudio,
    state,
    day
  });
  //

  // 다음 검사 주제(카테고리)로 이동하는 훅
  const navigateToNext = useNavigateToNext({
    handleReset,
    state,
    day,
    trainingType: "SHORT_STORY"
  });

  // 좋아요 기능
  const [isLike, setIsLike] = useState<boolean>(false);

  const handleLike = () => {
    setIsLike((prev) => !prev);
  };
  //

  // 현재 진행 중인 문제 / 전체 문제 개수
  const progressCount = !isCompleted
    ? trainingItemProgress
    : trainingItems && trainingItems.length;
  const progressTotalCount = trainingItems && trainingItems.length;

  return trainingItems ? (
    <>
      {isViewSentence && (
        <ViewSentencePopup
          onClose={() => setIsViewSentence(false)}
          storySentence={
            trainingItems
              ? trainingItems[trainingItemProgress - 1].context
              : "데이터 없음"
          }
          questionSentence={
            trainingItems
              ? trainingItems[trainingItemProgress - 1].questionContext
              : "데이터 없음"
          }
          storyAudioSrc={contextAudioUrls[trainingItemProgress - 1].url}
          questionAudioSrc={questionAudioUrls[trainingItemProgress - 1].url}
        />
      )}

      {/* 검사 페이지 메인 */}
      <main className="bg-[#fff] w-full h-full p-0 overflow-x-hidden">
        <div className="font-noto-sans-kr max-w-[1440px] h-full text-[18px] mx-auto my-0">
          <TestPageHeader title={state.title} navigateToBack={navigateToBack} />

          <div className="flex flex-col h-[calc(100vh_-_87px)] max-sm:h-[calc(100vh_-_65px)]">
            {/* 제목, 썸네일, 문장(퀴즈) 등이 표시되는 영역 */}
            <section
              className="flex-[3] flex flex-col justify-between w-full relative z-[1] p-[30px] max-sm:min-h-[465px] max-sm:p-4"
              style={backgroundStyle}
            >
              {/* 현재 검사 주제, '문장보기' 버튼을 렌더링하는 컴포넌트 */}
              <QuizTopicHeader
                isLike={isLike}
                isCompleted={isCompleted}
                itemName={itemName as string}
                handleLike={handleLike}
                handleViewSentence={handleViewSentence}
              />

              {/* 짧은 이야기 문장(퀴즈)과 응답 버튼이 렌더링되는 영역 */}
              <ShortStoriesListeningQuizContent
                isSolvingQuiz={isSolvingQuiz}
                isCompleted={isCompleted}
                isPlay={isPlay}
                handleSolvingQuiz={handleSolvingQuiz}
                handleReplayQuiz={replayQuiz}
                trainingItemProgress={trainingItemProgress}
                responses={responses}
                message={message}
                answerList={answerList}
                correctAnswer={correctAnswer}
                selectedAnswer={selectedAnswer}
                setSelectedAnswer={setSelectedAnswer}
                isQuestionPlay={isQuestionPlay}
                trainingItems={trainingItems}
                responseCounts={responseCounts}
              />

              {/* 이전/다음 문제 이동 버튼, 현재 퀴즈의 순서를 렌더링하는 영역 */}
              <QuizNavigation
                isCompleted={isCompleted}
                blinkNextButton={blinkNextButton}
                trainingItemProgress={trainingItemProgress}
                handlePreviousQuestion={handlePreviousQuestion}
                handleNextQuestion={handleNextQuestion}
                progressCount={progressCount as number}
                progressTotalCount={progressTotalCount as number}
              />
            </section>

            {/* 듣기, 듣기 설정 등 제어 영역 */}
            <ListeningControlSection
              backgroundStyle={backgroundStyle}
              isCompleted={isCompleted}
              handlePlay={handlePlay}
              isPlay={isPlay}
              currentWave={currentWave}
              handleShowSettings={handleShowSettings}
              handleReset={handleReset}
              navigateToNext={navigateToNext}
              location={location}
              state={state}
            />
          </div>
        </div>

        {/* 빠르기 듣기 설정 영역 */}
        {shouldRender && !isCompleted && (
          <AudioSettingsModal
            showSettings={showSettings}
            handleShowSettings={handleShowSettings}
            handleStopBubble={handleStopBubble}
            speed={speed}
            handleSpeedChange={handleSpeedChange}
          />
        )}
      </main>
    </>
  ) : (
    <></>
  );
}
