import { RecoilRoot } from "recoil";
import "./App.css";

import MainRoutes from "@components/common/routes/MainRoutes";

function App() {
  return (
    <RecoilRoot>
      <MainRoutes />
    </RecoilRoot>
  );
}

export default App;
