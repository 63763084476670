import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import ViewSentencePopup from "@components/mobile/ViewSentencePopup";
import TestPageHeader from "@components/tests/TestPageHeader";
import QuizTopicHeader from "@components/tests/QuizTopicHeader";
import LongStoriesListeningQuizContent from "@components/tests/quizContents/LongStoriesListeningQuizContent";
import QuizNavigation from "@components/tests/QuizNavigation";
import ListeningControlSection from "@components/tests/ListeningControlSection";
import AudioSettingsModal from "@components/tests/AudioSettingsModal";

// import { taskCompletionState, tasksState } from "@states/progress";
import { showOptionsState } from "@states/settings";

import { ROUTES } from "@constants/common.routes";
import { TrainingType } from "@constants/trainingType";

import useAppData from "@hooks/useAppData";
import useWaveAnimation from "@hooks/useWaveAnimation";
import useBackgroundStyle from "@hooks/useBackgroundStyle";
import usePlaybackSpeed from "@hooks/usePlaybackSpeed";
import useAudioSettings from "@hooks/useAudioSettings";

import Utils from "@libs/utils";
import { ITrainingResponseRecord } from "@libs/common.types";
import type {
  AudioURLType,
  TrainingResponseCountType,
  TrainingItemType,
  TrainingQuestionItemsType,
  TrainingIsCorrectType
} from "@libs/common.types";
import useNavigateToNext from "@hooks/useNavigateToNext";
import useNavigateToBack from "@hooks/useNavigateToBack";

export default function LongStoriesListening() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { itemName, day } = useParams<{ itemName: string; day: string }>();
  const { getSignedSoundBucket } = useAppData();

  const [trainingGroupId, setTrainingGroupId] = useState<string | null>();
  const [trainingItems, setTrainingItems] = useState<TrainingItemType[]>();
  const [trainingItemProgress, setTrainingItemProgress] = useState(0);
  const [questionItems, setQuestionItems] = useState<
    TrainingQuestionItemsType[]
  >([]);
  const [currentQuestionItem, setCurrentQuestionItem] =
    useState<TrainingQuestionItemsType>();
  const [questionItemProgress, setQuestionItemProgress] = useState(0);

  const [isCompleted, setIsCompleted] = useState(false);

  const [audioUrls, setAudioUrls] = useState<AudioURLType[]>([]);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [isPlay, setIsPlay] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1.0); // 기본 재생 속도

  const [message, setMessage] = useState(""); // 문제 내용을 담을 상태
  const [isSolvingQuiz, setIsSolvingQuiz] = useState(false);
  const [isClickAnswer, setIsClickAnswer] = useState(false); // 보기 버튼 클릭 여부
  const [selectedAnswer, setSelectedAnswer] = useState<string[]>([]); // 선택된 응답
  const [correctAnswer, setCorrectAnswer] = useState<string[]>([]); // 정답
  const [answerList, setAnswerList] = useState<string[]>([]); // 보기 리스트

  const [blinkNextButton, setBlinkNextButton] = useState(false); // 응답 시 다음 문제 버튼 애니메이션을 관리하는 상태

  const showOptions = useRecoilValue(showOptionsState); // 보기 표시 여부 Recoil 상태 값 읽기

  const [responses, setResponses] = useState<ITrainingResponseRecord>({});
  const [currentResponse, setCurrentResponse] =
    useState<TrainingIsCorrectType | null>(null);
  const [responseCounts, setResponseCounts] =
    useState<TrainingResponseCountType>({
      correct: 0,
      incorrect: 0
    });

  // 재생 중일 때 듣기 버튼 애니메이션 적용 훅
  const currentWave = useWaveAnimation(isPlay);

  // 배경 이미지 적용 훅
  const { p_code, code } = state.categoryData;
  const backgroundStyle = useBackgroundStyle(p_code, code, isCompleted);

  // 재생 속도 조절 훅
  const { speed, handleSpeedChange } = usePlaybackSpeed(audio);

  // 듣기 설정 확장 훅
  const { showSettings, shouldRender, handleShowSettings, handleStopBubble } =
    useAudioSettings();

  // const [tasks, setTasks] = useRecoilState(tasksState);

  // "이전 문제" 또는 "다음 문제" 버튼을 클릭했을 때의 로직
  // const [taskCompletion, setTaskCompletion] =
  //   useRecoilState(taskCompletionState);

  // console.log("trainingGroupId", trainingGroupId);
  // console.log("trainingItems", trainingItems);
  // console.log("trainingItemProgress", trainingItemProgress);
  // console.log("questionItemProgress", questionItemProgress);
  // console.log("responses", responses);
  // console.log("responseCounts", responseCounts);

  useEffect(() => {
    // escaping direct url loading -> temporary solution
    // TODO: show error massage and redirect to main page
    // TODO: or reload data gracefully
    if (!state) navigate("/main");
  }, [state]);

  useEffect(() => {
    console.log("audioUrls", audioUrls);
  }, [audioUrls]);

  // 문제 데이터 불러오기
  useEffect(() => {
    if (!state) return;
    const currentItemIndex = state.currentIndex;
    const currentItem = state.items[currentItemIndex];
    const item = Utils.findTrainingItemsById(state.type, currentItem.id);

    if (item) {
      if (!(item.items && item.items.length > 0)) {
        alert("문제 데이터를 준비중입니다.");
        navigateToBack();
        return;
      }

      setTrainingItems(item.items);
      // TrainingItemProgress
      // LongStory: items는 대부분 1개의 그룹만 존재하나 배열일때도 고려
      // LongStory: TrainingItemProgress는 그 배열의 순서를 나타냄
      setTrainingItemProgress(1);
      setTrainingGroupId(item.id);
    }
  }, [state && state.currentIndex]);
  //

  useEffect(() => {
    if (
      trainingItemProgress !== 0 &&
      trainingItems &&
      trainingItems.length > 0
    ) {
      const currentIndex = trainingItemProgress - 1;
      if (trainingItems[currentIndex]) {
        const qItems = trainingItems[currentIndex].questionItems;
        if (qItems && qItems.length > 0) {
          // QuestionItemProgress init
          console.log("QuestionItemProgress init", qItems);
          setQuestionItems(qItems);
          setCurrentQuestionItem(qItems[0]);
          setQuestionItemProgress(1);
        }
      }
    }
  }, [trainingItemProgress, trainingItems]);

  // 문제 번호 변경 시 응답 초기화
  useEffect(() => {
    /** TODO: Progress 상태 업데이트 */
    // 0: 준비 상태, 1: 문제풀이 시작번호, questionItems.length < questionItemProgress: 문제풀이 완료
    // inProgress 또는 inComplete
    if (questionItems && questionItems.length > 0) {
      setCurrentQuestionItem(questionItems[questionItemProgress - 1]);
      setIsCompleted(questionItemProgress > questionItems.length);
      setCurrentResponse(null);
      setSelectedAnswer([]);
      setIsClickAnswer(false);
    }
  }, [questionItemProgress]);

  useEffect(() => {
    if (currentQuestionItem) {
      let answerList: string[] = [];
      let wrongAnswerList: string[] = [];
      if (questionItemProgress === 1) {
        answerList = currentQuestionItem.answer.split("|");
        wrongAnswerList = currentQuestionItem.wrongAnswer1.split("|");
      } else {
        answerList = [currentQuestionItem.answer];
        wrongAnswerList = [
          currentQuestionItem.wrongAnswer1,
          currentQuestionItem.wrongAnswer2
        ];
      }

      // random merge correct and wrong answers
      const mergedAnswers = Utils.arrayMergeAndShuffle(
        answerList,
        wrongAnswerList
      );

      if (mergedAnswers.length === 0) return;

      setMessage(currentQuestionItem.questionContext);
      setAnswerList(mergedAnswers);
      setCorrectAnswer(answerList);
    }
  }, [currentQuestionItem]);

  useEffect(() => {
    console.log("questionItems", questionItems);
  }, [questionItems]);

  useEffect(() => {
    if (!state) return;
    if (!(trainingItems && trainingItems.length > 0)) return;

    async function getBucket(filePath: string) {
      if (filePath === "") return;
      return await getSignedSoundBucket(filePath);
    }

    // TODO: 문제 로직마다 다르게 설정해야 할 수 있음.(ex: 짧은 이야기)
    const promisses = trainingItems.map(async (item) => {
      const path = Utils.generateAudioBucketPath(state.type, item.contextCode);
      if (path) {
        const result = await getBucket(path);
        if (result && result.data) {
          return { index: item.index, path: path, url: result.data.signedUrl };
        }
      }
    });

    // TODO: 순서보장 확인
    // 만약 예외 확인되면 index로 sort
    Promise.all(promisses)
      .then((results) => {
        return results.map((res) => res as AudioURLType);
      })
      .then((urls) => {
        const newValues = urls.filter((url) => url !== undefined);
        setAudioUrls(newValues);
        return newValues;
      })
      .then((newValues) => {
        setAudio(new Audio(newValues[0].url));
      });
  }, [trainingGroupId]);

  // 문장 보기 버튼을 클릭했을 때의 로직
  const [isViewSentence, setIsViewSentence] = useState(false);
  const handleViewSentence = () => {
    setIsViewSentence(!isViewSentence);
  };

  useEffect(() => {
    if (questionItems && questionItems.length > 0) {
      // updateAnswers(questionItems, questionItemProgress);
    }
  }, [questionItems, questionItemProgress]);

  // 현재 문장의 오디오 파일 경로를 업데이트하는 로직
  useEffect(() => {
    if (!trainingItems) return;
    if (trainingItems.length === 0) return;
    if (audioUrls.length === 0) return;
    if (audioUrls.length !== trainingItems.length) return;

    const audio = audioUrls.find(
      (audio) => audio.index === trainingItemProgress
    );

    if (audio) {
      const newAudio = new Audio(audio.url);
      newAudio.playbackRate = playbackRate;
      setAudio(newAudio);
    }
  }, [audioUrls, trainingItemProgress]);

  // 오디오 재생 및 정지 로직
  useEffect(() => {
    if (!audio) return;

    if (isPlay) {
      audio.play();
    } else {
      audio.pause();
    }

    audio.onended = () => {
      setIsPlay(false); // 사운드가 종료되면 isPlay 상태를 false로 설정
    };

    // 재생 속도 설정
    audio.playbackRate = playbackRate;

    return () => {
      audio.pause();
    };
  }, [audio, isPlay]);

  const initAudioPlay = () => {
    if (audio) audio.currentTime = 0; // 오디오 초기화
    setIsPlay(false);
  };

  const initTrainingProgressData = () => {
    console.log("initTrainingProgressData");
    setIsClickAnswer(false);
    setMessage("");
    setIsViewSentence(false);
    setSelectedAnswer([]);
    setResponses({});
  };

  // 문제 사운드 재생 속도 업데이트
  useEffect(() => {
    if (audio) {
      audio.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  useEffect(() => {
    if (questionItemProgress > 1 && selectedAnswer.length > 0) {
      handleAnswer(selectedAnswer, questionItemProgress);
    }
  }, [selectedAnswer]);

  const deleteRespState = (key: number) => {
    setResponses((prev) => {
      const { [key]: _, ...newState } = prev;
      return newState;
    });
  };

  const handlePreviousQuestion = () => {
    if (audio) audio.pause();
    setIsPlay(false);

    if (isCompleted) {
      setIsCompleted(false);
    }

    // 문제 뒤로가기 시, 현재, 이전 문제의 응답을 제거
    deleteRespState(questionItemProgress);
    deleteRespState(questionItemProgress - 1);

    setQuestionItemProgress(questionItemProgress - 1);
  };

  const handleNextQuestion = () => {
    if (!responses[questionItemProgress]) {
      alert("응답을 선택해 주세요!");
      return;
    }

    initAudioPlay();

    initTrainingProgressData();
    setQuestionItemProgress(questionItemProgress + 1);

    // "다음 문제" 버튼 강조 효과 제거
    setBlinkNextButton(false);
  };

  // TODO: 컴포넌트 분리를 위해 argument 추가
  const handleAnswer = (answer: string[], progress: number) => {
    // if (responses[progress]) return;
    let isCorrect = false;
    if (currentQuestionItem) {
      if (progress === 1) {
        const _answer = currentQuestionItem.answer.split("|");
        console.log("answer", answer, _answer);
        isCorrect = Utils.arraysEqual(_answer, answer);
      } else {
        isCorrect = currentQuestionItem.answer === answer[0];
      }
    }

    setIsClickAnswer(true);

    const msg = isCorrect ? "정답입니다!" : "아쉽네요!";
    const ans = answer[0];
    const resp = isCorrect ? "correct" : "incorrect";

    setMessage(msg);
    setResponses((prev) => {
      const updatedResponses = {
        ...prev,
        [progress]: { answer: ans, response: resp }
      } as ITrainingResponseRecord;

      let correctCount = 0;
      let incorrectCount = 0;
      for (const [key, value] of Object.entries(updatedResponses)) {
        console.log("updatedResponses", key, value);
        // console.log(`${key}: ${value}`);
        if (value && value.response) {
          if (value.response === "correct") {
            correctCount++;
          } else if (value.response === "incorrect") {
            incorrectCount++;
          }
        }
      }
      setResponseCounts({ correct: correctCount, incorrect: incorrectCount });

      return updatedResponses;
    });

    setCurrentResponse(resp);

    // 응답 후 "다음 문제" 버튼에 강조 효과 추가
    setBlinkNextButton(true);
  };

  const handlePlay = () => {
    if (!audio) return;
    setIsPlay(!isPlay);
  };

  const handleSolvingQuiz = () => {
    setIsSolvingQuiz(true);
    setIsPlay(false);
  };

  // "한번 더 듣기" 버튼 클릭 시 상태 초기화
  const handleReset = () => {
    if (audio) audio.currentTime = 0; // 오디오를 처음으로 되돌림
    setIsPlay(false); // 문제 듣기 상태를 초기화
    setIsSolvingQuiz(false); // 문장 보기 상태를 초기화
    setQuestionItemProgress(1); // 문제 번호를 초기화
    setIsCompleted(false); // 테스트 완료 상태를 초기화
    setResponses({}); // 사용자의 응답 저장 객체를 초기화
    setSelectedAnswer([]); // 선택된 응답 초기화
    setIsClickAnswer(false); // 클릭 상태 초기화
    setMessage(""); // 메시지 초기화
  };

  // 뒤로가기 훅
  const navigateToBack = useNavigateToBack({
    setAudioUrls,
    setAudio,
    state,
    day
  });

  // 다음 검사 주제(카테고리)로 이동하는 훅
  const navigateToNext = useNavigateToNext({
    handleReset,
    state,
    day,
    trainingType: "LONG_STORY"
  });

  // 좋아요 기능
  const [isLike, setIsLike] = useState<boolean>(false);

  const handleLike = () => {
    setIsLike(!isLike);
  };

  // 현재 진행 중인 문제 / 전체 문제 개수
  const progressCount = !isCompleted
    ? questionItemProgress
    : questionItems && questionItems.length;
  const progressTotalCount = questionItems && questionItems.length;

  return (
    <>
      {trainingItems && isViewSentence && (
        <ViewSentencePopup
          onClose={() => setIsViewSentence(false)}
          storySentence={trainingItems[trainingItemProgress - 1].context}
          questionSentence=""
          storyAudioSrc={audioUrls[trainingItemProgress - 1].url}
        />
      )}

      {/* 검사 페이지 메인 */}
      <main className="bg-[#fff] w-full h-full p-0 overflow-x-hidden">
        <div className="font-noto-sans-kr max-w-[1440px] h-full text-[18px] mx-auto my-0">
          <TestPageHeader title={state.title} navigateToBack={navigateToBack} />

          <div className="flex flex-col h-[calc(100vh_-_87px)] max-sm:h-[calc(100vh_-_65px)]">
            {/* 제목, 썸네일, 문장(퀴즈) 등이 표시되는 영역 */}
            <section
              className="flex-[3] flex flex-col justify-between w-full relative z-[1] p-[30px] max-sm:min-h-[465px] max-sm:p-4"
              style={backgroundStyle}
            >
              {/* 현재 검사 주제, '문장보기' 버튼을 렌더링하는 컴포넌트 */}
              <QuizTopicHeader
                isLike={isLike}
                isCompleted={isCompleted}
                itemName={itemName as string}
                handleLike={handleLike}
                handleViewSentence={handleViewSentence}
              />

              {/* 긴 이야기 문장(퀴즈)과 응답 버튼이 렌더링되는 영역 */}
              <LongStoriesListeningQuizContent
                isCompleted={isCompleted}
                isSolvingQuiz={isSolvingQuiz}
                isPlay={isPlay}
                handleSolvingQuiz={handleSolvingQuiz}
                currentQuestionItem={
                  currentQuestionItem as TrainingQuestionItemsType
                }
                trainingItemProgress={trainingItemProgress}
                questionItemProgress={questionItemProgress}
                responses={responses}
                message={message}
                answerList={answerList}
                correctAnswer={correctAnswer}
                selectedAnswer={selectedAnswer}
                setSelectedAnswer={setSelectedAnswer}
                responseCounts={responseCounts}
                handleAnswer={handleAnswer}
              />

              {/* 이전/다음 문제 이동 버튼, 현재 퀴즈의 순서를 렌더링하는 영역 */}
              <QuizNavigation
                isCompleted={isCompleted}
                blinkNextButton={blinkNextButton}
                trainingItemProgress={trainingItemProgress}
                handlePreviousQuestion={handlePreviousQuestion}
                handleNextQuestion={handleNextQuestion}
                progressCount={progressCount as number}
                progressTotalCount={progressTotalCount as number}
              />
            </section>

            {/* 듣기, 듣기 설정 등 제어 영역 */}
            <ListeningControlSection
              backgroundStyle={backgroundStyle}
              isCompleted={isCompleted}
              handlePlay={handlePlay}
              isPlay={isPlay}
              currentWave={currentWave}
              handleShowSettings={handleShowSettings}
              handleReset={handleReset}
              navigateToNext={navigateToNext}
              location={location}
              state={state}
            />
          </div>
        </div>

        {/* 빠르기 듣기 설정 영역 */}
        {shouldRender && !isCompleted && (
          <AudioSettingsModal
            showSettings={showSettings}
            handleShowSettings={handleShowSettings}
            handleStopBubble={handleStopBubble}
            speed={speed}
            handleSpeedChange={handleSpeedChange}
          />
        )}
      </main>
    </>
  );
}
