import { Link, useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import {
  TaskCompletionState,
  taskCompletionState,
  taskProgressState
} from "@states/progress";
import Utils from "@libs/utils";

import type { ListeningLinksProps } from "@libs/common.types";

export default function ListeningLinks({
  type,
  items,
  categoryData,
  page
}: ListeningLinksProps) {
  const taskCompletion =
    useRecoilValue<TaskCompletionState>(taskCompletionState);
  // const taskProgress = useRecoilValue(taskProgressState); // 진행 상황 상태 가져오기
  const { day } = useParams();
  const location = useLocation();

  const bgColor = Utils.getTrainingTitleColorStr(type);
  const koTitle = Utils.getTrainingTitleNameByKey(type);

  // 경로에 /category/가 포함되어 있는지 확인(주제별 듣기인지)
  const isCategoryPath = location.pathname.includes("/category/");

  return (
    <div
      className={`flex flex-col justify-start items-center w-full my-5 bg-${bgColor}`}
    >
      <div
        className={`w-full p-4 border border-opacity-15 rounded-[12px_12px_0_0] ${
          bgColor === "blue"
            ? "bg-blueMain"
            : bgColor === "magenta"
            ? "bg-magentaMain"
            : bgColor === "purple"
            ? "bg-purpleMain"
            : bgColor === "mint"
            ? "bg-peacockMain"
            : ""
        }`}
      >
        <p className="text-[24px] font-extrabold text-white text-center tracking-[1px]">
          {koTitle}
        </p>
      </div>
      <div
        className={`w-full border ${
          isCategoryPath ? "p-4" : "py-0 px-[30px]"
        } rounded-[0_0_12px_12px] border-solid border-[rgba(0,122,255,0.15)] ${
          isCategoryPath
            ? "grid grid-rows-[auto] grid-cols-[repeat(5,1fr)] gap-4 max-sm:grid-cols-[1fr_1fr] "
            : ""
        }`}
      >
        {items.length > 0 ? (
          items.map((item, index) => {
            const taskKey = `${type}-${item.name}`; // 주제 이름과 title을 포함한 식별자 설정
            const isCompleted = taskCompletion[taskKey] || false; // taskCompletion 상태에서 해당 key 확인
            const trainingItems = Utils.findTrainingItemsById(type, item.id);
            const isEnabled =
              trainingItems &&
              trainingItems.items &&
              trainingItems.items.length > 0;

            // const progress = isCompleted
            //   ? item.totalQuestions
            //   : taskProgress[taskKey] || 0; // 완료된 경우 전체 문제 개수로 고정

            // URL 경로에 따라 상태 설정 로직을 분기
            const state =
              location.pathname.includes("/today") ||
              location.pathname.includes("/yesterday") ||
              location.pathname.includes("/listening_by_training")
                ? {
                    type: type,
                    title: koTitle,
                    items: items,
                    currentIndex: index,
                    categoryData: {
                      p_code: item.category_main_code,
                      code: item.category_sub_code,
                      page: page
                    }
                  }
                : {
                    type: type,
                    title: koTitle,
                    items: items,
                    currentIndex: index,
                    categoryData: categoryData,
                    p_code: item.category_main_code,
                    code: item.category_sub_code
                  };

            return (
              <div
                key={item.id}
                className={`flex justify-center items-center ${
                  isCategoryPath ? "m-0" : "my-4"
                }  rounded-[100px] font-noto-sans-kr text-[20px] font-semibold text-white text-center transition-all duration-300 ${
                  isCompleted
                    ? "relative bg-[rgba(99,180,255,0.1)] text-white font-normal"
                    : ""
                } ${
                  isEnabled
                    ? "hover:scale-[1.03]"
                    : "hover:bg-[repeating-linear-gradient(_-45deg,_transparent,_transparent_10px,_rgba(255,_255,_255,_0.3)_10px,_rgba(255,_255,_255,_0.3)_20px_)]"
                } ${
                  bgColor === "blue"
                    ? "bg-blueSub"
                    : bgColor === "magenta"
                    ? "bg-magentaSub"
                    : bgColor === "purple"
                    ? "bg-purpleSub"
                    : bgColor === "mint"
                    ? "bg-peacockSub"
                    : ""
                }`.replace(/\s+/g, " ")}
              >
                <Link
                  className="w-full p-3 max-sm:text-[16px]"
                  to={Utils.generateListeningLinkPath(type, item.name, day)}
                  state={state}
                  onClick={(e) => {
                    if (!isEnabled) {
                      e.preventDefault();
                      alert("문제 데이터를 준비중입니다.");
                    }
                  }}
                >
                  {item.name}
                  {/* 
                    TODO: 문제 상태 관리(taskProgress) 개선
                    {["today", "yesterday"].includes(day as string) &&
                    ["소음하 문장듣기"].includes(title) &&
                    `(${progress}/${item.totalQuestions})`} 
                  */}
                </Link>
                {isCompleted && (
                  <img
                    className="absolute right-8"
                    src={`${process.env.PUBLIC_URL}/images/icons/icon_task_complete.png`}
                    alt="Completed Icon"
                  />
                )}
              </div>
            );
          })
        ) : (
          <div className="flex justify-center items-center my-4 rounded-[100px] font-noto-sans-kr text-[20px] font-semibold text-center transition-all duration-300 text-[#454545] bg-[white]">
            연습 데이터가 없습니다
          </div>
        )}
      </div>
    </div>
  );
}
