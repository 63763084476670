import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropResult } from "react-beautiful-dnd";

import TestPageHeader from "@components/tests/TestPageHeader";
import QuizTopicHeader from "@components/tests/QuizTopicHeader";
import SentenceOrderingQuizContent from "@components/tests/quizContents/SentenceOrderingQuizContent";
import QuizNavigation from "@components/tests/QuizNavigation";
import ListeningControlSection from "@components/tests/ListeningControlSection";

import { taskCompletionState, tasksState } from "@states/progress";

import { ROUTES } from "@constants/common.routes";
import { TrainingType } from "@constants/trainingType";

import useAppData from "@hooks/useAppData";
import useWaveAnimation from "@hooks/useWaveAnimation";
import useBackgroundStyle from "@hooks/useBackgroundStyle";

import Utils from "@libs/utils";
import type {
  TrainingItemType,
  AudioURLType,
  TrainingResponseType,
  TrainingResponseCountType
} from "@libs/common.types";
import useNavigateToNext from "@hooks/useNavigateToNext";
import useNavigateToBack from "@hooks/useNavigateToBack";

export default function SentenceOrdering() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { itemName, day } = useParams<{ itemName: string; day: string }>();
  const { getSignedSoundBucket } = useAppData();

  const defaultMessage = "먼저 문장을 하나씩 모두 듣고 순서를 완성해보세요!";
  const questionMessage = "문장 박스를 움직여 순서를 맞추어 보세요!";

  const [trainingGroupId, setTrainingGroupId] = useState<string | null>();
  const [trainingItems, setTrainingItems] = useState<TrainingItemType[]>();
  const [trainingItemProgress, setTrainingItemProgress] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);

  const [audioUrls, setAudioUrls] = useState<AudioURLType[]>([]);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [isPlay, setIsPlay] = useState(false);

  // 퀴즈 풀기 버튼을 클릭했을 때의 로직
  const [isStartSolvingQuiz, setIsStartSolvingQuiz] = useState(false);

  // const [responses, setResponses] = useState<ITrainingResponseRecord>({});
  const [currentResponse, setCurrentResponse] =
    useState<TrainingResponseType | null>(null);
  const [responseCounts, setResponseCounts] =
    useState<TrainingResponseCountType>({
      correct: 0,
      incorrect: 0
    });

  // console.log("trainingGroupId", trainingGroupId);
  // console.log("trainingItems", trainingItems);
  // console.log("trainingItemProgress", trainingItemProgress);

  const [message, setMessage] = useState(defaultMessage);

  // 재생 중일 때 듣기 버튼 애니메이션
  const currentWave = useWaveAnimation(isPlay);

  // 배경 이미지 적용 로직
  const { p_code, code } = state.categoryData;
  const backgroundStyle = useBackgroundStyle(p_code, code, isCompleted);

  useEffect(() => {
    // escaping direct url loading -> temporary solution
    // TODO: show error massage and redirect to main page
    // TODO: or reload data gracefully
    if (!state) navigate("/main");
  }, [state]);

  useEffect(() => {
    console.log("audioUrls", audioUrls);
  }, [audioUrls]);

  // 문제 데이터 불러오기
  useEffect(() => {
    if (!state) return;
    const currentItemIndex = state.currentIndex;
    const currentItem = state.items[currentItemIndex];
    const item = Utils.findTrainingItemsById(state.type, currentItem.id);

    if (item) {
      if (!(item.items && item.items.length > 0)) {
        alert("문제 데이터를 준비중입니다.");
        navigateToBack();
        return;
      }
      const shuffledItems = Utils.shuffleArray(item.items); // 셔플된 items
      setTrainingItems(shuffledItems);
      setCorrectOrder(item.items.map((item) => item.index.toString())); // 정답 순서를 설정, 문자열 배열로 변환

      // TrainingItemProgress
      // 0: 준비 상태, 1: 문제풀이 시작번호, trainingItems.length : 문제풀이 완료
      setTrainingItemProgress(1);
      setTrainingGroupId(item.id);
    }
  }, [state && state.currentIndex]);
  //

  // "잘들렸어요" 또는 "안들렸어요" 응답 처리 로직
  // 문제 번호 변경 시 응답 초기화
  useEffect(() => {
    if (trainingItems && trainingItems.length > 0) {
      setIsCompleted(trainingItemProgress > trainingItems.length);
      setIsStartSolvingQuiz(trainingItemProgress > trainingItems.length);
      setCurrentResponse(null);
    }
  }, [trainingItemProgress]);

  useEffect(() => {
    if (!state) return;
    if (!(trainingItems && trainingItems.length > 0)) return;

    async function getBucket(filePath: string) {
      if (filePath === "") return;
      const result = await getSignedSoundBucket(filePath);
      // console.log(`Fetched URL for ${filePath}:`, result.data?.signedUrl);
      return result;
    }

    // TODO: 문제 로직마다 다르게 설정해야 할 수 있음.(ex: 짧은 이야기)
    const promisses = trainingItems.map(async (item) => {
      const path = Utils.generateAudioBucketPath(state.type, item.contextCode);
      if (path) {
        const result = await getBucket(path);
        if (result && result.data) {
          return { index: item.index, path: path, url: result.data.signedUrl };
        }
      }
    });

    // TODO: 순서보장 확인
    // 만약 예외 확인되면 index로 sort
    Promise.all(promisses)
      .then((results) => {
        return results.map((res) => res as AudioURLType);
      })
      .then((urls) => {
        const newValues = urls.filter((url) => url !== undefined);
        setAudioUrls(newValues);
        return newValues;
      })
      .then((newValues) => {
        setAudio(new Audio(newValues[0].url));
      });
  }, [trainingGroupId]);

  useEffect(() => {
    if (isStartSolvingQuiz) {
      setMessage(questionMessage);
    } else {
      handleReset();
      setTrainingItemProgress(1);
    }
  }, [isStartSolvingQuiz]);

  // "이전 문제" 또는 "다음 문제" 버튼을 클릭했을 때의 로직
  // const [taskCompletion, setTaskCompletion] = useRecoilState(taskCompletionState);
  // const [isTestCompleted, setIsTestCompleted] = useState(false); // 테스트 완료 상태

  // 이전 문제로 이동하는 함수
  const handlePreviousQuestion = () => {
    if (audio) audio.pause();
    setIsPlay(false);
    setTrainingItemProgress(trainingItemProgress - 1);
  };

  // 다음 문제로 이동하는 함수
  const handleNextQuestion = () => {
    // if (currentResponse === null) {
    //   alert("응답을 선택해 주세요!");
    //   return;
    // }

    setIsPlay(false);
    if (audio) audio.pause();
    setTrainingItemProgress(trainingItemProgress + 1);
  };

  // 재생/일시정지 버튼 클릭 시 로직 수정
  const handlePlay = () => {
    setIsPlay((prev) => !prev);
  };

  // 현재 문장의 오디오 파일 경로를 업데이트하는 로직
  useEffect(() => {
    if (!trainingItems) return;
    if (trainingItems.length === 0) return;
    if (audioUrls.length === 0) return;
    if (audioUrls.length !== trainingItems.length) return;

    const audio = audioUrls.find(
      (audio) => audio.index === trainingItemProgress
    );
    if (audio) {
      const newAudio = new Audio(audio.url);
      setAudio(newAudio);
    }
  }, [audioUrls, trainingItemProgress]);
  //

  // 오디오 재생 로직
  useEffect(() => {
    if (!audio) return;

    if (isPlay) {
      audio.play();
    } else {
      audio.pause();
    }

    audio.onended = () => {
      setIsPlay(false);
    };

    return () => {
      audio.pause();
    };
  }, [audio, isPlay]);

  const handleSolvingQuiz = () => {
    setIsStartSolvingQuiz(!isStartSolvingQuiz);
  };

  // 드래그 앤 드롭 로직
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    if (!trainingItems) return;

    const newItems = Array.from(trainingItems);
    const [relocatedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, relocatedItem);
    setTrainingItems(newItems);
  };

  // 드래그 시 스타일
  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    ...draggableStyle,
    background: isDragging ? "#1575fb" : "#40a0ff"
  });

  // 정답 확인 로직
  // results 타입 정의
  type Result = {
    isCorrect: boolean;
    correctIndex: number;
  };
  // 각 문장의 정답 위치를 가정한 예시 배열
  const [correctOrder, setCorrectOrder] = useState<string[]>([]); // 정답 순서를 저장
  const [results, setResults] = useState<Result[]>([]); // 정답 결과 배열
  const [correctCount, setCorrectCount] = useState<number | null>(null); // 정답 개수를 저장

  const [isSolved, setIsSolved] = useState(false); // 퀴즈가 해결되었는지 상태 관리
  const [isCheckAnswer, setIsCheckAnswer] = useState(false);

  const [taskCompletion, setTaskCompletion] =
    useRecoilState(taskCompletionState);

  // 정답 확인 로직을 처리하는 핸들러
  const handleCheckAnswer = () => {
    if (!trainingItems) return;

    // 정답 순서와 사용자가 배치한 순서를 비교
    const userOrder = trainingItems.map((item) => item.index.toString());
    const isCorrect =
      JSON.stringify(userOrder) === JSON.stringify(correctOrder);

    // 정답 확인 결과를 설정
    const checkResults = trainingItems.map((item, index) => ({
      isCorrect: item.index.toString() === correctOrder[index],
      correctIndex: correctOrder.indexOf(item.index.toString()) + 1
    }));

    setResults(checkResults);

    const correctCount = checkResults.filter(
      (result) => result.isCorrect
    ).length;
    setCorrectCount(correctCount);
    setIsSolved(true);
    setIsCheckAnswer(true);
    setMessage("정답률");
  };
  //

  // "다시 풀기" 버튼 클릭 시 상태 초기화
  const handleReset = () => {
    setMessage(defaultMessage);
    setIsCheckAnswer(false);
    setIsSolved(false);
    setResults([]);
  };

  // 뒤로가기 훅
  const navigateToBack = useNavigateToBack({
    setAudioUrls,
    setAudio,
    state,
    day
  });

  // 다음 검사 주제(카테고리)로 이동하는 훅
  const navigateToNext = useNavigateToNext({
    handleReset,
    state,
    day,
    setIsStartSolvingQuiz,
    trainingType: "ORDERING_SENTENCE"
  });

  // 좋아요 기능
  const [isLike, setIsLike] = useState<boolean>(false);

  const handleLike = () => {
    setIsLike((prev) => !prev);
  };
  //

  // 현재 진행 중인 문제 / 전체 문제 개수
  const progressCount = !isCompleted
    ? trainingItemProgress
    : trainingItems && trainingItems.length;
  const progressTotalCount = trainingItems && trainingItems.length;

  return (
    // 검사 페이지 메인
    <main className="bg-[#fff] w-full h-full p-0 overflow-x-hidden">
      <div className="font-noto-sans-kr max-w-[1440px] h-full text-[18px] mx-auto my-0">
        <TestPageHeader title={state.title} navigateToBack={navigateToBack} />

        <div className="flex flex-col h-[calc(100vh_-_87px)] max-sm:h-[calc(100vh_-_65px)]">
          {/* 제목, 썸네일, 문장(퀴즈) 등이 표시되는 영역 */}
          <section
            className="flex-[3] flex flex-col justify-between w-full relative z-[1] p-[30px] max-sm:min-h-[465px] max-sm:p-4"
            style={backgroundStyle}
          >
            {/* 현재 검사 주제, '문장보기' 버튼을 렌더링하는 컴포넌트 */}
            <QuizTopicHeader
              isCompleted={isCompleted}
              isLike={isLike}
              itemName={itemName as string}
              handleLike={handleLike}
              handleViewSentence={() => {}}
              isStartSolvingQuiz={isStartSolvingQuiz}
              isSolved={isSolved}
              handleSolvingQuiz={handleSolvingQuiz}
            />

            {/* 문장 순서화 문장(퀴즈)과 응답 버튼이 렌더링되는 영역 */}
            <SentenceOrderingQuizContent
              isStartSolvingQuiz={isStartSolvingQuiz}
              isSolved={isSolved}
              correctCount={correctCount}
              trainingItems={trainingItems}
              message={message}
              onDragEnd={onDragEnd}
              getItemStyle={getItemStyle}
              results={results}
            />

            {/* 이전/다음 문제 이동 버튼, 현재 퀴즈의 순서를 렌더링하는 영역 */}
            <QuizNavigation
              isCompleted={isCompleted}
              blinkNextButton={false}
              trainingItemProgress={trainingItemProgress}
              handlePreviousQuestion={handlePreviousQuestion}
              handleNextQuestion={handleNextQuestion}
              progressCount={progressCount as number}
              progressTotalCount={progressTotalCount as number}
            />
          </section>

          <ListeningControlSection
            backgroundStyle={backgroundStyle}
            isCompleted={isCompleted}
            handlePlay={handlePlay}
            isPlay={isPlay}
            currentWave={currentWave}
            handleShowSettings={() => {}}
            handleReset={handleReset}
            navigateToNext={navigateToNext}
            location={location}
            state={state}
            isStartSolvingQuiz={isStartSolvingQuiz}
            isCheckAnswer={isCheckAnswer}
            handleCheckAnswer={handleCheckAnswer}
          />
        </div>
      </div>
    </main>
  );
}
