import { ReactComponent as PlayIcon } from "@public/images/icons/icon_play-alt.svg";
import { ReactComponent as PauseIcon } from "@public/images/icons/icon_pause-alt.svg";
import QuestionHeader from "../QuestionHeader";
import QuestionsSelectComponent from "../QuestionsSelectComponent";
import { ITrainingResponseRecord } from "@libs/common.types";

type ShortStoriesListeningQuizContentProps = {
  isSolvingQuiz: boolean;
  isCompleted: boolean;
  isPlay: boolean;
  handleSolvingQuiz: () => void;
  handleReplayQuiz: () => void;
  trainingItemProgress: number;
  responses: ITrainingResponseRecord;
  message: string;
  answerList: string[];
  correctAnswer: string[];
  selectedAnswer: string[];
  setSelectedAnswer: React.Dispatch<React.SetStateAction<string[]>>;
  isQuestionPlay: boolean;
  trainingItems?: { length: number };
  responseCounts: { correct: number; incorrect: number };
};

export default function ShortStoriesListeningQuizContent({
  isSolvingQuiz,
  isCompleted,
  isPlay,
  handleSolvingQuiz,
  handleReplayQuiz,
  trainingItemProgress,
  responses,
  message,
  answerList,
  correctAnswer,
  selectedAnswer,
  setSelectedAnswer,
  isQuestionPlay,
  trainingItems,
  responseCounts
}: ShortStoriesListeningQuizContentProps) {
  return (
    <div className="flex flex-col justify-center items-center w-full min-h-[300px] max-h-full overflow-x-hidden overflow-y-scroll no-scrollbar">
      {!isSolvingQuiz && !isCompleted && (
        <>
          <p className="font-noto-sans-kr text-2xl font-semibold text-white leading-[160%] text-center break-keep mt-0 mb-3 mx-0 underline">
            아래 플레이 버튼을 눌러 문장을 잘 들어보세요.
            <br />잘 들으신 뒤 문장을 확인하세요.
          </p>
          <button
            className={`flex justify-center items-center min-w-[80px] text-xl font-semibold break-keep bg-white px-8 py-3 rounded-[100px] ${
              isPlay ? "cursor-not-allowed bg-[#a0a0a0] text-[#696969]" : ""
            }`}
            onClick={handleSolvingQuiz}
          >
            <PlayIcon width={20} height={20} />
            &nbsp;&nbsp;퀴즈풀기
          </button>
        </>
      )}
      {isSolvingQuiz && !isCompleted && (
        <>
          <QuestionHeader
            progress={trainingItemProgress}
            resp={responses}
            isSolvingQuiz={isSolvingQuiz}
            message={message}
          />
          <QuestionsSelectComponent
            progress={trainingItemProgress}
            resp={responses}
            ansList={answerList}
            correctAnswer={correctAnswer}
            selAnswer={selectedAnswer}
            setSelectedAnswer={setSelectedAnswer}
            isCompleted={isCompleted}
          />
          <button
            className={`flex justify-center items-center min-w-[80px] text-xl font-semibold break-keep bg-white px-8 py-3 rounded-[100px] mt-[12px] ${
              isPlay ? "cursor-not-allowed bg-[#a0a0a0] text-[#696969]" : ""
            }`}
            onClick={handleReplayQuiz}
          >
            {isQuestionPlay ? (
              <PauseIcon width={20} height={20} />
            ) : (
              <PlayIcon width={20} height={20} />
            )}
            &nbsp;&nbsp;다시듣기
          </button>
        </>
      )}
      {!isSolvingQuiz && isCompleted && (
        <>
          <p className="text-white font-bold text-[24px] leading-7 text-center tracking-[1px] break-keep">
            짝짝짝! <br />
            모든 문장을 다 들었습니다. <br />
            이제 다음 듣기를 해 볼까요?
          </p>
          <hr className="w-[30%] mx-0 my-4" />
          <div className="flex gap-4 items-center max-sm:gap-2">
            <img
              src={`${process.env.PUBLIC_URL}/images/test/trophy.png`}
              alt="Trophy Icon"
            />
            <div className="text-white font-bold text-[24px] leading-7 text-center tracking-[1px] break-keep">
              퀴즈 {trainingItems?.length}개 중 {responseCounts.correct}개 정답!
            </div>
          </div>
        </>
      )}
    </div>
  );
}
