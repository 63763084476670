import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";

import MobileDailyTaskButton from "@components/mobile/MobileDailyTaskButton";
import MobileMainNavigator from "@components/mobile/MobileMainNavigator";
import MobileTopicListeningOptions from "@components/mobile/MobileTopicListeningOptions";

import { ROUTES } from "@constants/common.routes";

import {
  taskCompletionState,
  taskProgressState,
  tasksState
} from "@states/progress";
import { UserAuthState } from "@states/UserAuthState";
import { DailyTrainingState } from "@states/DailyTrainingState";
import ListeningByTrainingButtons from "@components/mobile/ListeningByTrainingButtons";

export default function Home() {
  const authState = useRecoilValue(UserAuthState);

  const { profile } = authState;

  const [tasks, setTasks] = useRecoilState(tasksState);
  const [progress, setProgress] = useRecoilState(taskCompletionState);
  const [taskProgress, setTaskProgress] = useRecoilState(taskProgressState);

  // 초기화 함수
  const resetTasks = () => {
    setTasks([]);
    setProgress({});
    setTaskProgress({});
    useResetRecoilState(DailyTrainingState);
  };

  return (
    <div className="bg-[#fff] w-full h-[calc(100vh_-_75px)] pt-[30px] pb-0 px-0 max-sm:h-screen max-sm:overflow-x-hidden">
      <div className="flex flex-row justify-center items-center max-w-[1440px] h-auto mx-auto my-0 relative pt-0 p-[30px]">
        <div className="flex flex-col justify-center items-center w-full">
          {/* 웰컴 메시지 */}
          <div className="flex justify-center items-center gap-20 w-full max-sm:justify-between max-sm:gap-0">
            <p className="text-[#0d1b34] text-[1.5rem] font-semibold break-keep max-sm:text-[1.1rem]">
              [{profile && profile.name}] 님 안녕하세요! <br />
              오늘도 말귀와 함께 <br className="hidden max-sm:block" />더 잘
              들리는 하루를 보내볼까요?
            </p>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/icon_welcome.png`}
              alt="Welcome Icon"
              className="h-full"
              onClick={resetTasks}
            />
          </div>
          {/* 웰컴 메시지 끝 */}

          {/* 오늘의 듣기 */}
          <MobileMainNavigator
            sectionTitle="오늘의 듣기"
            sectionGuideText="오늘의 듣기를 진행해 보세요!"
          >
            <MobileDailyTaskButton />
          </MobileMainNavigator>
          {/* 오늘의 듣기 끝 */}

          {/* 가로세로퀴즈 */}
          <MobileMainNavigator
            sectionTitle="가로세로퀴즈"
            sectionGuideText="퀴즈를 풀며 즐겁게 들어봐요"
          >
            <Link
              to={ROUTES.CROSSWORD}
              className="font-noto-sans-kr w-full bg-[#40a0ff] text-[20px] font-semibold text-center text-white p-3 rounded-[100px]"
            >
              퀴즈 풀러 출발!
            </Link>
          </MobileMainNavigator>
          {/* 가로세로퀴즈 끝 */}

          {/* 주제별 듣기 */}
          <MobileMainNavigator
            sectionTitle="주제별 듣기"
            sectionGuideText="주제를 선택하여 흥미롭게 들어보세요"
          >
            <MobileTopicListeningOptions />
          </MobileMainNavigator>
          {/* 주제별 듣기 끝 */}

          {/* 훈련별 듣기 */}
          {/* 일반 유저가 아닐 경우에만 렌더링 */}
          {profile.role !== "ROLE_USER" && (
            <MobileMainNavigator
              sectionTitle="훈련별 듣기"
              sectionGuideText=""
            >
              <ListeningByTrainingButtons />
            </MobileMainNavigator>
          )}
          {/* 훈련별 듣기 끝 */}
        </div>
      </div>
    </div>
  );
}
