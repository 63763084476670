import { ReactComponent as CircleCheck } from "@public/images/icons/icon_circle-check-alt.svg";
import { ReactComponent as CircleClose } from "@public/images/icons/icon_circle-close-alt.svg";
import Utils from "@libs/utils";
import { ITrainingResponseRecord } from "@libs/common.types";

type QuestionSelectComponentProps = {
  progress: number;
  resp: ITrainingResponseRecord;
  ansList: string[];
  correctAnswer: string[];
  selAnswer: string[];
  setSelectedAnswer: React.Dispatch<React.SetStateAction<string[]>>;
  isCompleted: boolean;
};

const QuestionsSelectComponent = ({
  progress,
  resp,
  ansList,
  correctAnswer,
  selAnswer,
  setSelectedAnswer,
  isCompleted
}: QuestionSelectComponentProps) => {
  const currentResp = resp[progress];

  const handleMergeAnswer = (answer: string) => {
    setSelectedAnswer([answer]);
  };

  const AnswerStatus = {
    DEFAULT: "default",
    CORRECT: "correct",
    INCORRECT: "incorrect"
  };

  const getAnswerStatus = (
    resp: ITrainingResponseRecord,
    correctAns: string[],
    answer: string
  ) => {
    if (resp) {
      if (Utils.arrayHasElem(correctAns, [answer])) {
        return AnswerStatus.CORRECT;
      } else {
        return AnswerStatus.INCORRECT;
      }
    }

    return AnswerStatus.DEFAULT;
  };

  const getColorClass = (answerStatus: string) => {
    if (answerStatus === AnswerStatus.DEFAULT) {
      return "bg-[#8696bb]";
    } else if (answerStatus === AnswerStatus.CORRECT) {
      return "bg-[#63b4ff]";
    } else if (answerStatus === AnswerStatus.INCORRECT) {
      return "bg-[#ff4571]";
    }
  };

  return (
    <>
      <div className="flex justify-center items-center gap-4 w-[85vw] flex-wrap text-white my-3">
        {ansList.map((answer, index) => {
          const id = `answer-${index}`;
          const answerStatus = getAnswerStatus(
            currentResp,
            correctAnswer,
            answer
          );
          const colorClass = getColorClass(answerStatus);

          return (
            <div className="relative" key={id}>
              {currentResp &&
                currentResp.response &&
                selAnswer.includes(answer) &&
                (answerStatus === AnswerStatus.CORRECT ? (
                  <CircleCheck
                    className="absolute top-[-10px] right-[-4px]"
                    width={24}
                    height={24}
                    fill="#00FF00"
                  />
                ) : (
                  <CircleClose
                    className="absolute top-[-10px] right-[-4px]"
                    width={24}
                    height={24}
                    fill="#FF0000"
                  />
                ))}
              <button
                className={`flex justify-center items-center min-w-[80px] text-xl font-semibold break-keep px-4 py-3 rounded-[100px] ${colorClass}`}
                onClick={() => handleMergeAnswer(answer)}
                disabled={currentResp !== undefined || isCompleted}
              >
                {answer}
              </button>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default QuestionsSelectComponent;
