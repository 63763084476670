import {
  ITrainingResponseRecord,
  TrainingQuestionItemsType
} from "@libs/common.types";
import QuestionBottom from "../QuestionBottom";
import QuestionHeader from "../QuestionHeader";
import QuestionsMultiSelectComponent from "../QuestionsMultiSelectComponent";
import QuestionsSelectComponent from "../QuestionsSelectComponent";

type LongStoriesListeningQuizContentProps = {
  isCompleted: boolean;
  isSolvingQuiz: boolean;
  isPlay: boolean;
  handleSolvingQuiz: () => void;
  currentQuestionItem: TrainingQuestionItemsType;
  trainingItemProgress: number;
  questionItemProgress: number;
  responses: ITrainingResponseRecord;
  message: string;
  answerList: string[];
  correctAnswer: string[];
  selectedAnswer: string[];
  setSelectedAnswer: React.Dispatch<React.SetStateAction<string[]>>;
  responseCounts: { correct: number; incorrect: number };
  handleAnswer: (answer: string[], progress: number) => void;
};

export default function LongStoriesListeningQuizContent({
  isCompleted,
  isSolvingQuiz,
  isPlay,
  handleSolvingQuiz,
  currentQuestionItem,
  trainingItemProgress,
  questionItemProgress,
  responses,
  message,
  answerList,
  correctAnswer,
  selectedAnswer,
  setSelectedAnswer,
  responseCounts,
  handleAnswer
}: LongStoriesListeningQuizContentProps) {
  return (
    <div className="flex flex-col justify-center items-center w-full min-h-[300px] max-h-full overflow-x-hidden overflow-y-scroll no-scrollbar">
      {!isCompleted ? (
        !isSolvingQuiz ? (
          <>
            <p className="font-noto-sans-kr text-2xl font-semibold text-white leading-[160%] text-center break-keep mt-0 mb-3 mx-0 underline">
              아래 플레이 버튼을 눌러 문장을 잘 들어보세요.
              <br />잘 들으신 뒤 문장을 확인하세요.
            </p>
            <button
              className={`flex justify-center items-center min-w-[80px] text-xl font-semibold break-keep bg-white px-8 py-3 rounded-[100px] ${
                isPlay ? "cursor-not-allowed bg-[#a0a0a0] text-[#696969]" : ""
              }`}
              onClick={handleSolvingQuiz}
            >
              퀴즈풀기
            </button>
          </>
        ) : (
          currentQuestionItem && (
            <>
              <QuestionHeader
                progress={trainingItemProgress}
                resp={responses}
                isSolvingQuiz={isSolvingQuiz}
                message={message}
              />
              {currentQuestionItem.index === 1 ? (
                <QuestionsMultiSelectComponent
                  progress={questionItemProgress}
                  resp={responses}
                  ansList={answerList}
                  correctAnswer={correctAnswer}
                  selAnswer={selectedAnswer}
                  setSelectedAnswer={setSelectedAnswer}
                  isCompleted={isCompleted}
                />
              ) : (
                <QuestionsSelectComponent
                  progress={questionItemProgress}
                  resp={responses}
                  ansList={answerList}
                  correctAnswer={correctAnswer}
                  selAnswer={selectedAnswer}
                  setSelectedAnswer={setSelectedAnswer}
                  isCompleted={isCompleted}
                />
              )}

              <QuestionBottom
                progress={questionItemProgress}
                resp={responses}
                selAnswer={selectedAnswer}
                handleAnswer={handleAnswer}
              />
            </>
          )
        )
      ) : (
        <>
          <p className="text-white font-bold text-[24px] leading-7 text-center tracking-[1px] break-keep">
            짝짝짝! <br />
            모든 문장을 다 들었습니다. <br />
            이제 다음 듣기를 해 볼까요?
          </p>
          <hr className="w-[30%] mx-0 my-4" />
          <div className="flex gap-4 items-center max-sm:gap-2">
            <img
              src={`${process.env.PUBLIC_URL}/images/test/trophy.png`}
              alt="Trophy Icon"
            />
            <div className="text-white font-bold text-[24px] leading-7 text-center tracking-[1px] break-keep">
              퀴즈 {responseCounts.correct}개 정답!
            </div>
          </div>
        </>
      )}
    </div>
  );
}
