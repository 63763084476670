import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import TestPageHeader from "@components/tests/TestPageHeader";
import QuizTopicHeader from "@components/tests/QuizTopicHeader";
import LowNoiseListeningQuizContent from "@components/tests/quizContents/LowNoiseListeningQuizContent";
import QuizNavigation from "@components/tests/QuizNavigation";
import ListeningControlSection from "@components/tests/ListeningControlSection";
import AudioSettingsModal from "@components/tests/AudioSettingsModal";

import { ROUTES } from "@constants/common.routes";
import { TrainingType } from "@constants/trainingType";

import useAppData from "@hooks/useAppData";
import useWaveAnimation from "@hooks/useWaveAnimation";
import useBackgroundStyle from "@hooks/useBackgroundStyle";
import usePlaybackSpeed from "@hooks/usePlaybackSpeed";
import useAudioSettings from "@hooks/useAudioSettings";

import Utils from "@libs/utils";
import { ITrainingResponseRecord } from "@libs/common.types";
import type {
  TrainingItemType,
  TrainingResponseType,
  TrainingResponseCountType,
  AudioURLType
} from "@libs/common.types";
import useNavigateToNext from "@hooks/useNavigateToNext";
import useNavigateToBack from "@hooks/useNavigateToBack";

export default function LowNoiseListening() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { itemName, day } = useParams<{ itemName: string; day: string }>();
  const { getSignedSoundBucket } = useAppData();

  const [trainingGroupId, setTrainingGroupId] = useState<string | null>();
  const [trainingItems, setTrainingItems] = useState<TrainingItemType[]>();
  const [trainingItemProgress, setTrainingItemProgress] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);

  const [blinkNextButton, setBlinkNextButton] = useState(false); // 응답 시 다음 문제 버튼 애니메이션을 관리하는 상태

  const [audioUrls, setAudioUrls] = useState<AudioURLType[]>([]);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [isPlay, setIsPlay] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1.0); // 기본 재생 속도
  const [noiseAudio, setNoiseAudio] = useState<HTMLAudioElement | null>(null); // 소음 종류 오디오

  const [responses, setResponses] = useState<ITrainingResponseRecord>({});
  const [currentResponse, setCurrentResponse] =
    useState<TrainingResponseType | null>(null);
  const [responseCounts, setResponseCounts] =
    useState<TrainingResponseCountType>({
      correct: 0,
      incorrect: 0
    });

  // 재생 중일 때 듣기 버튼 애니메이션 적용 훅
  const currentWave = useWaveAnimation(isPlay);

  // 배경 이미지 적용 훅
  const { p_code, code } = state.categoryData;
  const backgroundStyle = useBackgroundStyle(p_code, code, isCompleted);

  // 재생 속도 조절 훅
  const { speed, handleSpeedChange } = usePlaybackSpeed(audio);

  // 듣기 설정 확장 훅
  const { showSettings, shouldRender, handleShowSettings, handleStopBubble } =
    useAudioSettings();

  // console.log("trainingGroupId", trainingGroupId);
  // console.log("trainingItems", trainingItems);
  // console.log("trainingItemProgress", trainingItemProgress);

  useEffect(() => {
    // escaping direct url loading -> temporary solution
    // TODO: show error massage and redirect to main page
    // TODO: or reload data gracefully
    if (!state) navigate("/main");
  }, [state]);

  useEffect(() => {
    console.log("audioUrls", audioUrls);
  }, [audioUrls]);

  // 문제 데이터 불러오기
  useEffect(() => {
    if (!state) return;
    const currentItemIndex = state.currentIndex;
    const currentItem = state.items[currentItemIndex];
    const item = Utils.findTrainingItemsById(state.type, currentItem.id);

    if (item) {
      if (!(item.items && item.items.length > 0)) {
        alert("문제 데이터를 준비중입니다.");
        navigateToBack();
        return;
      }
      setTrainingItems(item.items);
      // TrainingItemProgress
      // 0: 준비 상태, 1: 문제풀이 시작번호, trainingItems.length < setTrainingItemProgress: 문제풀이 완료
      setTrainingItemProgress(1);
      setTrainingGroupId(item.id);
    }
  }, [state && state.currentIndex]);
  //

  // "잘들렸어요" 또는 "안들렸어요" 응답 처리 로직
  // 문제 번호 변경 시 응답 초기화
  useEffect(() => {
    /** TODO: Progress 상태 업데이트 */
    // 0: 준비 상태, 1: 문제풀이 시작번호, trainingItems.length < setTrainingItemProgress: 문제풀이 완료
    // inProgress 또는 inComplete
    if (trainingItems && trainingItems.length > 0) {
      setIsCompleted(trainingItemProgress > trainingItems.length);
      setCurrentResponse(null);
    }
  }, [trainingItemProgress]);

  useEffect(() => {
    if (!state) return;
    if (!(trainingItems && trainingItems.length > 0)) return;

    async function getBucket(filePath: string) {
      if (filePath === "") return;
      return await getSignedSoundBucket(filePath);
    }

    // TODO: 문제 로직마다 다르게 설정해야 할 수 있음.(ex: 짧은 이야기)
    const promisses = trainingItems.map(async (item) => {
      const path = Utils.generateAudioBucketPath(state.type, item.contextCode);
      if (path) {
        const result = await getBucket(path);
        if (result && result.data) {
          return { index: item.index, path: path, url: result.data.signedUrl };
        }
      }
    });

    // TODO: 순서보장 확인
    // 만약 예외 확인되면 index로 sort
    Promise.all(promisses)
      .then((results) => {
        return results.map((res) => res as AudioURLType);
      })
      .then((urls) => {
        const newValues = urls.filter((url) => url !== undefined);
        setAudioUrls(newValues);
        return newValues;
      })
      .then((newValues) => {
        setAudio(new Audio(newValues[0].url));
      });
  }, [trainingGroupId]);

  // 문장 보기 버튼을 클릭했을 때의 로직
  const [isViewSentence, setIsViewSentence] = useState(false);
  const handleViewSentence = () => {
    setIsViewSentence((prev) => !prev);
  };

  // 각 설정(소음크기, 소음종류, 빠르기)에 대한 로직
  // 버튼은 클릭할 때마다 값이 순환됩니다.
  const [noiseLevel, setNoiseLevel] = useState(0); // 소음크기
  const [noiseType, setNoiseType] = useState<NoiseType>("기본소음"); // 소음종류

  const noiseTypes = ["기본소음", "길거리", "식당", "와글와글"];
  const noiseTypeImages = {
    기본소음: `${process.env.PUBLIC_URL}/images/backgrounds/noise/bg_normal.jpeg`,
    길거리: `${process.env.PUBLIC_URL}/images/backgrounds/noise/bg_street.jpeg`,
    식당: `${process.env.PUBLIC_URL}/images/backgrounds/noise/bg_restaurant.jpeg`,
    와글와글: `${process.env.PUBLIC_URL}/images/backgrounds/noise/bg_buzz.jpeg`
  } as const;
  // 소음 종류 타입
  type NoiseType = keyof typeof noiseTypeImages;

  const handleNoiseLevelChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNoiseLevel(Number(event.target.value));
  };

  const handleNoiseTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNoiseType(event.target.value as NoiseType);
  };

  // 소음종류(노이즈)에 따른 소음 재생 로직
  // 랜덤 파일 선택 로직 추가
  const getRandomNoiseFile = (type: string): string => {
    if (type === "길거리" || type === "식당") {
      const randomIndex = Math.floor(Math.random() * 10) + 1;
      return `${type === "길거리" ? "noise2" : "noise3"}-${randomIndex
        .toString()
        .padStart(2, "0")}.mp3`;
    } else if (type === "기본소음") {
      return "noise1.mp3";
    } else if (type === "와글와글") {
      return "noise4.mp3";
    }
    return "";
  };

  const handlePreviousQuestion = () => {
    setIsPlay(false);
    if (audio) audio.pause();

    setTrainingItemProgress(trainingItemProgress - 1);
  };

  const handleNextQuestion = () => {
    setIsPlay(false);
    if (audio) audio.pause();

    if (trainingItems) {
      if (trainingItemProgress <= trainingItems.length) {
        setTrainingItemProgress(trainingItemProgress + 1);
        setIsViewSentence(false);
      } else {
        setIsViewSentence(false); // 결과 화면으로 넘어갈 때 문장 보기 상태 초기화
      }
    }

    // "다음 문제" 버튼 강조 효과 제거
    setBlinkNextButton(false);
  };
  //

  const handleResponse = (response: TrainingResponseType) => {
    if (currentResponse === response) return; // 응답이 변경되지 않으면 아무것도 하지 않음

    setResponses((prev) => {
      const updatedResponses = { ...prev, [trainingItemProgress]: response };

      // 응답 개수를 업데이트하기 위해 이전 응답을 제거하고 새 응답을 추가
      const prevResponse = prev[trainingItemProgress];
      setResponseCounts((prevCounts) => {
        const newCounts = { ...prevCounts };
        if (prevResponse) {
          newCounts[prevResponse.response] -= 1;
        }
        newCounts[response.response] += 1;
        return newCounts;
      });

      return updatedResponses;
    });

    setCurrentResponse(response);

    // 응답 후 "다음 문제" 버튼에 강조 효과 추가
    setBlinkNextButton(true);
  };

  const handlePlay = () => {
    setIsPlay((prev) => !prev);
  };

  // 현재 문장의 오디오 파일 경로를 업데이트하는 로직
  useEffect(() => {
    if (!trainingItems) return;
    if (trainingItems.length === 0) return;
    if (audioUrls.length === 0) return;
    if (audioUrls.length !== trainingItems.length) return;

    const audioPath = audioUrls.find(
      (audio) => audio.index === trainingItemProgress
    );

    if (audioPath) {
      const newAudio = new Audio(audioPath.url);
      newAudio.playbackRate = playbackRate;
      setAudio(newAudio);
    }
  }, [audioUrls, trainingItemProgress]);

  /** 소음 크기 값에 따라 볼륨을 설정하는 함수 */
  const getNoiseVolume = (level: number): number => {
    return level === 0 ? 0 : level * 0.25; // level 0: 0(기본값), level 1: 0.25, level 2: 0.5, level 3: 0.75, level 4: 1
  };

  useEffect(() => {
    if (!audio) return;

    if (isPlay) {
      audio.play();
    } else {
      audio.pause();
    }

    audio.onended = () => {
      setIsPlay(false);
    };

    return () => {
      audio.pause();
    };
  }, [audio, isPlay]);

  // 소음 사운드 관리
  useEffect(() => {
    if (!trainingItems) return;
    if (trainingItems.length === 0) return;
    const noiseFilename = getRandomNoiseFile(noiseType);
    // 소음사운드는 static 파일로 관리

    if (noiseFilename) {
      const noiseAudioPath = `${process.env.PUBLIC_URL}/sounds/noise/${noiseFilename}`;
      const newNoiseAudio = new Audio(noiseAudioPath);
      newNoiseAudio.volume = getNoiseVolume(noiseLevel); // 소음 볼륨 설정
      setNoiseAudio(newNoiseAudio);

      if (isPlay) {
        newNoiseAudio.play();
      }
    }
  }, [noiseType, isPlay]);

  useEffect(() => {
    console.log("noiseAudio", noiseAudio);
    if (noiseAudio) {
      if (noiseLevel === 0) {
        noiseAudio.muted = true;
      } else {
        noiseAudio.muted = false;
      }

      console.log("isPlay", isPlay);
      if (isPlay && noiseAudio.paused) {
        if (!noiseAudio.muted) {
          noiseAudio.volume = getNoiseVolume(noiseLevel);
          noiseAudio
            .play()
            .then(() => {
              console.log("Playback started successfully");
              // noiseAudio.volume = getNoiseVolume(noiseLevel);
            })
            .catch((error) => {
              console.error("Error attempting to play:", error);
            });
        }
      } else if (!isPlay && !noiseAudio.paused) {
        noiseAudio.pause();
        noiseAudio.currentTime = 0;
      }

      noiseAudio.onended = () => {
        noiseAudio.currentTime = 0;
        noiseAudio.play();
      };

      return () => {
        noiseAudio.pause();
      };
    }
  }, [noiseAudio, isPlay, noiseLevel]);

  const handleReset = () => {
    setIsPlay(false); // 문제 듣기 상태를 초기화
    setIsViewSentence(false); // 문장 보기 상태를 초기화
    setTrainingItemProgress(1); // 테스트 진행 상황 초기화
    setIsCompleted(false); // 테스트 완료 상태를 초기화
    setResponseCounts({ correct: 0, incorrect: 0 }); // 응답 카운트 초기화
    setResponses({}); // 기존 응답 기록 초기화
    setCurrentResponse(null); // 현재 응답 상태를 초기화
  };

  // 뒤로가기 훅
  const navigateToBack = useNavigateToBack({
    setAudioUrls,
    setAudio,
    state,
    day
  });

  // 다음 검사 주제(카테고리)로 이동하는 훅
  const navigateToNext = useNavigateToNext({
    handleReset,
    state,
    day,
    trainingType: "LISTEN_IN_NOISE"
  });

  // 좋아요 기능
  const [isLike, setIsLike] = useState<boolean>(false);

  const handleLike = () => {
    setIsLike((prev) => !prev);
  };

  // 현재 진행 중인 문제 / 전체 문제 개수
  const progressCount = !isCompleted
    ? trainingItemProgress
    : trainingItems && trainingItems.length;
  const progressTotalCount = trainingItems && trainingItems.length;

  return trainingItems ? (
    // 검사 페이지 메인
    <main className="bg-[#fff] w-full h-full p-0 overflow-x-hidden">
      <div className="font-noto-sans-kr max-w-[1440px] h-full text-[18px] mx-auto my-0">
        <TestPageHeader title={state.title} navigateToBack={navigateToBack} />

        <div className="flex flex-col h-[calc(100vh_-_87px)] max-sm:h-[calc(100vh_-_65px)]">
          {/* 제목, 썸네일, 문장(퀴즈) 등이 표시되는 영역 */}
          <section
            className="flex-[3] flex flex-col justify-between w-full relative z-[1] p-[30px] max-sm:min-h-[465px] max-sm:p-4"
            style={backgroundStyle}
          >
            {/* 현재 검사 주제, '문장보기' 버튼을 렌더링하는 컴포넌트 */}
            <QuizTopicHeader
              isLike={isLike}
              isCompleted={isCompleted}
              itemName={itemName as string}
              handleLike={handleLike}
              handleViewSentence={handleViewSentence}
            />

            {/* 소음 하 문장(퀴즈)과 응답 버튼이 렌더링되는 영역 */}
            <LowNoiseListeningQuizContent
              isCompleted={isCompleted}
              isViewSentence={isViewSentence}
              trainingItems={trainingItems}
              trainingItemProgress={trainingItemProgress}
              responseCounts={responseCounts}
              currentResponse={currentResponse as TrainingResponseType}
              handleViewSentence={handleViewSentence}
              handleResponse={handleResponse}
            />

            {/* 이전/다음 문제 이동 버튼, 현재 퀴즈의 순서를 렌더링하는 영역 */}
            <QuizNavigation
              isCompleted={isCompleted}
              blinkNextButton={blinkNextButton}
              trainingItemProgress={trainingItemProgress}
              handlePreviousQuestion={handlePreviousQuestion}
              handleNextQuestion={handleNextQuestion}
              progressCount={progressCount as number}
              progressTotalCount={progressTotalCount as number}
            />
          </section>

          <ListeningControlSection
            backgroundStyle={backgroundStyle}
            isCompleted={isCompleted}
            handlePlay={handlePlay}
            isPlay={isPlay}
            currentWave={currentWave}
            handleShowSettings={handleShowSettings}
            handleReset={handleReset}
            navigateToNext={navigateToNext}
            location={location}
            state={state}
          />
        </div>
      </div>

      {/* 소음, 빠르기 듣기 설정 영역 */}
      {shouldRender && !isCompleted && (
        <AudioSettingsModal
          showSettings={showSettings}
          handleShowSettings={handleShowSettings}
          handleStopBubble={handleStopBubble}
          speed={speed}
          handleSpeedChange={handleSpeedChange}
          noiseLevel={noiseLevel}
          handleNoiseLevelChange={handleNoiseLevelChange}
          noiseTypes={noiseTypes}
          noiseType={noiseType}
          handleNoiseTypeChange={handleNoiseTypeChange}
          noiseTypeImages={noiseTypeImages}
        />
      )}
    </main>
  ) : (
    <></>
  );
}
