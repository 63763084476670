import { useEffect } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { User } from '@supabase/supabase-js';

import { UserAuthState } from '@states/UserAuthState';
import { supabase } from '@libs/supabaseClient';

import type { UserAuthType } from '@states/UserAuthState';
import { DailyTrainingDefault, DailyTrainingState } from '@states/DailyTrainingState';
import { Tables } from '@libs/supabase.types';

const useAuth = () => {
  const [authSession, setAuthSession] = useRecoilState(UserAuthState);
  const resetAuth = useResetRecoilState(UserAuthState);
  const setDailyTraining = useSetRecoilState(DailyTrainingState);

  const uaDestruct = (user: User): UserAuthType => {
    return {
      id: user.id,
      email: user.email,
      user_metadata: user.user_metadata,
      aud: user.aud,
      last_sign_in_at: user.last_sign_in_at,
      updated_at: user.updated_at,
      created_at: user.created_at,
    };
  }

  const resetState = () => {
    resetAuth();
    // useResetRecoil(DailyTrainingState) not work;
    setDailyTraining(DailyTrainingDefault);
  }

  useEffect(() => {
    setTimeout(async () => {
      const { data, error } = await getSbSession();
      console.log("useAuth::init::getSessionData", data);

      if (error) {
        throw error;
      }

      if (data) {
        const { session } = data;
        console.log("useAuth::init::getSessionData::session", session);
        if (session) {
          const user = uaDestruct(session.user);
          if (!authSession.profile) {
            const profile = await getProfile(session.user.id);
            
            if (profile) {
              setAuthSession({
                user: user,
                profile: profile,
                isAuthenticated: session.user.aud ? true : false,
              });
            }
            
          } else {
            setAuthSession({
              ...authSession,
              user: user,
              isAuthenticated: session.user.aud ? true : false,
            });
          }
        } else {
          console.log("useAuth::init::but_not_sb_session", session);
        }
      }
    }, 0);

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session: any) => {
      setTimeout(async () => {
        console.log("useAuth::subs::event", _event);
        console.log("useAuth::subs::session", session);
        if (_event === 'SIGNED_OUT') {
          resetState();
        } else {
          if (session) {
            const user = uaDestruct(session.user);
            setAuthSession({
              ...authSession,
              user: user,
              isAuthenticated: session.user.aud ? true : false,
            });
          } else {
            console.log("useAuth::subs::but_not_sb_session");
            console.log(window.location);
            resetState();
            if (window.location.pathname.includes('/main')) {
              alert("로그인 세션이 종료되었습니다. 다시 로그인 해주세요.");
              window.location.href = "/";
            }
          }
        }
      }, 0);
    })

    return () => subscription.unsubscribe();
  }, [setAuthSession]);

  const getSbSession = async () => {
    return await supabase.auth.getSession();
  }

  const signUp = async ({ profile, email, password } : { profile: { name: string }, email: string, password: string }) =>{
    const getURL = () => {
      let url = window.location.origin ?? 'http://localhost:3000';
      url = url.includes('http') ? url : `https://${url}`;
      // url = url.charAt(url.length - 1) === '/' ? url : `${url}/`;
      return url;
    };
    
    const origin = getURL();
    console.log("emailRedirectTo", origin);
    const { data, error } = await supabase.auth.signUp({
      email: email,
      password: password,
      options: {
        // TODO: confirm email verification 처리
        emailRedirectTo: `${origin}`,
        data: {
          user_name: profile.name,
        },
      },
    });
  
    if (error) {
      throw error;
    }

    return true;
  };

  const signIn = async ({ email, password } : { email: string, password: string }) => {
    const { data, error } = await supabase.auth.signInWithPassword({ email, password });
    
    if (error) {
      throw error;
    }

    const { session } = data;
    if (session && session.user) {
      const profile = await getProfile(session.user.id);
      
      if (profile) {
        const user = uaDestruct(session.user);
      
        setAuthSession({
          user: user,
          profile: profile,
          isAuthenticated: session.user.aud ? true : false,
        });
      }
    }

    return true;
  };

  const signOut = async () => {
    const { error } = await supabase.auth.signOut()
    if (error) {
      throw error;
    }
    
    return true;
  };

  const getProfile = async (user_id: string) => {
    const { data, error } = await supabase
      .from('profiles')
      .select('*')
      .eq('user_id', user_id)
      .single();

    if (error) {
      throw error;
    }
    
    return data as Tables<'profiles'>;
  }

  const updateProfile = async (user_id: string, _data: any ) => {
    const { data, error } = await supabase
      .from('profiles')
      .update(_data)
      .eq('user_id', user_id)
      .select()
      .single();

    if (error) {
      throw error;
    }

    return data as Tables<'profiles'>;
  }

  return { 
    authSession, 
    signUp, 
    signIn, 
    signOut, 
    getSbSession,
    getProfile,
    updateProfile 
  };
};

export default useAuth;