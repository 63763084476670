import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

type ViewSentencePopupProps = {
  onClose: () => void;
  storySentence: string;
  questionSentence?: string;
  storyAudioSrc: string;
  questionAudioSrc?: string;
};

export default function ViewSentencePopup({
  onClose,
  storySentence,
  questionSentence,
  storyAudioSrc,
  questionAudioSrc
}: ViewSentencePopupProps) {
  const location = useLocation();

  // 이야기 듣기 토글 로직
  const [isPlayStory, setIsPlayStory] = useState(false);
  const [storyAudio, setStoryAudio] = useState<HTMLAudioElement | null>(null);

  const handlePlayStory = () => {
    setIsPlayStory(!isPlayStory);
    setIsPlayQuestion(false);
  };

  useEffect(() => {
    if (storyAudioSrc) {
      const audio = new Audio(storyAudioSrc);
      setStoryAudio(audio);
    }
  }, [storyAudioSrc]);

  useEffect(() => {
    if (storyAudio) {
      if (isPlayStory) {
        storyAudio.play();
      } else {
        storyAudio.pause();
      }

      storyAudio.onended = () => {
        setIsPlayStory(false);
      };

      return () => {
        storyAudio.pause();
      };
    }
  }, [isPlayStory, storyAudio]);

  // 문제 듣기 토글 로직
  const [isPlayQuestion, setIsPlayQuestion] = useState(false);
  const [questionAudio, setQuestionAudio] = useState<HTMLAudioElement | null>(
    null
  );

  const handlePlayQuestion = () => {
    setIsPlayQuestion(!isPlayQuestion);
    setIsPlayStory(false);
  };

  useEffect(() => {
    if (questionAudioSrc) {
      const audio = new Audio(questionAudioSrc);
      setQuestionAudio(audio);
    }
  }, [questionAudioSrc]);

  useEffect(() => {
    if (questionAudio) {
      if (isPlayQuestion) {
        questionAudio.play();
      } else {
        questionAudio.pause();
      }

      questionAudio.onended = () => {
        setIsPlayQuestion(false);
      };

      return () => {
        questionAudio.pause();
      };
    }
  }, [isPlayQuestion, questionAudio]);

  return (
    <div
      className="font-noto-sans-kr fixed z-[999] flex justify-center w-full h-full bg-[rgba(0,0,0,0.15)] border border-solid border-[rgba(0,122,255,0.15)] left-0 top-0"
      onClick={onClose}
    >
      <div
        className="absolute w-[90%] h-[95%] bg-[#f3f3f3] overflow-y-scroll p-4 left-[5%] top-[3%] no-scrollbar"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex justify-end">
          <button className="popup-close-button" onClick={onClose}>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/icon_close.png`}
              alt="Popup Close Icon"
            />
          </button>
        </div>

        <p className="text-2xl font-extrabold text-center mx-0 my-4">
          문장 보기
        </p>

        <PopupContent
          subject="이야기"
          handlePlay={handlePlayStory}
          isPlay={isPlayStory}
          sentence={storySentence}
        />
        {location.pathname.includes("/short_story/") && (
          <PopupContent
            subject="문제"
            handlePlay={handlePlayQuestion}
            isPlay={isPlayQuestion}
            sentence={questionSentence}
          />
        )}
      </div>
    </div>
  );
}

type PopupContentProps = {
  subject: string;
  handlePlay: () => void;
  isPlay: boolean;
  sentence?: string;
};
function PopupContent({
  subject,
  handlePlay,
  isPlay,
  sentence
}: PopupContentProps) {
  return (
    <div className="my-4 mx-0">
      <div className="flex justify-center items-start rounded-[12px_12px_0_0] bg-[#cbe1ff] border p-4 border-solid border-[rgba(0,122,255,0.15)]">
        <p className="text-2xl font-semibold text-[#4894fe] leading-[160%] text-center">
          {subject}
        </p>
        <button className="ml-4" onClick={handlePlay}>
          <img
            src={`${process.env.PUBLIC_URL}/images/icons/icon_popup_${
              isPlay ? "pause" : "play"
            }.png`}
            alt="Popup Sentence Play Icon"
          />
        </button>
      </div>
      <div className="bg-white break-keep rounded-[0_0_12px_12px] border p-4 border-solid border-[rgba(0,122,255,0.15)]">
        <p className="text-2xl font-semibold text-[#4894fe] leading-[160%] text-center">
          {sentence}
        </p>
      </div>
    </div>
  );
}
