import { useLocation } from "react-router-dom";

type QuizTopicHeaderProps = {
  isLike: boolean;
  isCompleted: boolean;
  itemName: string;
  handleLike: () => void;
  handleViewSentence: () => void;

  isStartSolvingQuiz?: boolean;
  isSolved?: boolean;
  handleSolvingQuiz?: () => void;
};

export default function QuizTopicHeader({
  handleLike,
  isLike,
  itemName,
  isCompleted,
  handleViewSentence,

  isStartSolvingQuiz = false,
  isSolved = false,
  handleSolvingQuiz = () => {}
}: QuizTopicHeaderProps) {
  const location = useLocation();

  if (location.pathname.includes("ordering_sentence")) {
    return (
      <div
        className={`font-pretendard-variable flex justify-end text-white w-full max-sm:min-h-[32px] max-sm:flex-row ${
          isStartSolvingQuiz ? "mb-4" : ""
        }`}
      >
        <div className="flex items-center justify-end w-full">
          {!isSolved && (
            <>
              <div className="flex items-center gap-4 flex-[5] text-4xl font-bold max-sm:flex-1 max-sm:gap-1">
                <button className="w-[30px] p-0" onClick={handleLike}>
                  <img
                    className="w-full max-sm:w-[20px]"
                    src={`${process.env.PUBLIC_URL}/images/icons/icon_like${
                      isLike ? "_fill" : ""
                    }.png`}
                    alt="Like Icon"
                  />
                </button>
                <p className="leading-0 max-sm:text-[28px]">{itemName}</p>
              </div>
              <button
                className="text-[16px] font-semibold px-3 py-1.5 rounded-[100px] border-2 border-solid border-white"
                onClick={handleSolvingQuiz}
              >
                {!isStartSolvingQuiz ? "퀴즈풀기" : "문장듣기"}
              </button>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="font-pretendard-variable flex justify-between text-white max-sm:min-h-[32px] max-sm:flex-row">
      <div className="flex items-center gap-4 flex-[5] text-4xl font-bold max-sm:flex-1 max-sm:gap-1">
        <button className="w-[30px] p-0" onClick={handleLike}>
          <img
            className="w-full max-sm:w-[20px]"
            src={`${process.env.PUBLIC_URL}/images/icons/icon_like${
              isLike ? "_fill" : ""
            }.png`}
            alt="Like Icon"
          />
        </button>
        <p className="leading-0 max-sm:text-[28px]">{itemName}</p>
      </div>
      <div className="flex items-center justify-end gap-4">
        {!isCompleted && (
          <button
            className="flex-1 text-base font-semibold px-3 py-1.5 rounded-[100px] border-2 border-solid border-white"
            onClick={handleViewSentence}
          >
            문장보기
          </button>
        )}
      </div>
    </div>
  );
}
