import { CSSProperties } from "react";
import { useLocation, Link } from "react-router-dom";

type NavigationButtonProps = {
  quizNumber: number;
};

export default function NavigationButton({
  quizNumber
}: NavigationButtonProps) {
  const location = useLocation();

  const isActive = location.pathname === `/main/training/part5/${quizNumber}`;
  const isDisabled = quizNumber !== 1;
  // const buttonStyle: CSSProperties = {
  //   color: isActive ? "#40A0FF" : isDisabled ? "gray" : "#8696BB",
  //   pointerEvents: isDisabled ? "none" : "auto",
  //   opacity: isDisabled ? "0.5" : "1",
  //   cursor: isDisabled ? "not-allowed" : "pointer"
  // };

  return (
    <Link
      to={isDisabled ? "/main" : `/main/training/part5/${quizNumber}`}
      className={isActive ? "" : "disabled"}
      // style={buttonStyle}
    >
      퀴즈 <br /> {quizNumber}
    </Link>
  );
}
