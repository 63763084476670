import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { CategoryMainState } from "@states/DataTypeState";

export default function MobileTopicListeningOptions() {
  const categoryMainState = useRecoilValue(CategoryMainState);
  const { data } = categoryMainState;
  console.log("MobileTopicListeningOptions", data);

  return (
    <div className="grid grid-rows-[auto] grid-cols-[repeat(3,1fr)] gap-8 max-sm:gap-[32px_16px] max-xs:grid-cols-[repeat(2,1fr)] max-xs:gap-6">
      {data &&
        data.length > 0 &&
        data.map((item: any) => (
          <Link
            to={`category/${item.route}`}
            className="flex flex-col justify-center items-center"
            key={item.code}
          >
            <div className="flex justify-center items-center w-[100px] h-[100px] bg-[rgba(99,180,255,0.15)] rounded-[20px] max-sm:w-[70px] max-sm:h-[70px]">
              <img
                className="h-[70px] max-sm:h-[50px]"
                src={`${process.env.PUBLIC_URL}/images/icons/topic/icon_${item.route}.png`}
                alt={`${item.route} Icon`}
              />
            </div>
            <p className="font-medium text-[18px] text-[#4894fe] mt-3 mb-0 mx-0">
              {item.name}
            </p>
          </Link>
        ))}
    </div>
  );
}
